import {message} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import {ApiService} from "../../../core/api/api"

import styles from "../../../styles/AdminManager.module.scss"

const CoreManager: FC = () => {

  const { store } = useContext(Context)
  const location = useLocation()

  const [oldPwd, setOldPwd] = useState("")
  const [newPwd, setNewPwd] = useState("")
  const [newPwdRpt, setNewPwdRpt] = useState("")

  const savePwd = async () => {
    const res = await ApiService.users.updateAccount({
      password: newPwd
    })

    setNewPwd(prev => prev = "")
    setNewPwdRpt(prev => prev = "")

    message.success("Пароль успешно изменен!")
  }

  const isPwdFilled = () => {
    if (
      newPwd.length < 3 || newPwdRpt.length < 3
    ) {
      return false
    } else {
      if (newPwd == newPwdRpt) {
        return true
      } else {
        return false
      }
    }
  }

  useEffect(() => {

  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className={styles.setting_block}>
              <h3>Сменить пароль</h3>
                
              <div className={styles.xform}>
                <div className={styles.xform_input}>
                  <label htmlFor="new_pwd">Новый пароль</label>
                  <input type="text" id="new_pwd" placeholder="Введите новый пароль" value={newPwd} onChange={(event) => setNewPwd(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="new_pwd">Повторите новый пароль</label>
                  <input type="text" id="new_pwd" placeholder="Повторите новый пароль" value={newPwdRpt} onChange={(event) => setNewPwdRpt(event.target.value)} />
                </div>
              </div>

              <button className={styles.xbtn} disabled={!isPwdFilled()} onClick={() => savePwd()}>Сохранить</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(CoreManager)