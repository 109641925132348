import { makeAutoObservable } from "mobx";
import {IXSeoConfig} from "../models/IXSeo";
import {IXAboutSettings} from "../models/XAbout";
import {IXContacts} from "../models/XContacts";
import {IXProject} from "../models/XProject";

export default class AdminStore {

  footerData: any = {}
  metaData: any = {}
  homeImages: string[] = []
  sliderIndex: number = 0
  sliderInited: boolean = false
  homeSliderSpeed: number = 0
  interSet: any = null

  translations: any = {}
  transes: any = []
  transActive: boolean = false

  pages: any[] = []
  contacts: IXContacts | null = null

  projects: IXProject[] = []

  dragId: number = 0

  isSettingsInited: boolean = false

  isAboutActive: boolean = true
  aboutLoad: number = 0
  aboutSettings: IXAboutSettings | null = null

  seoConfig: null | IXSeoConfig = null

  constructor() {
    makeAutoObservable(this)
  }

  setSeoConfig(val: IXSeoConfig | null) {
    this.seoConfig = val
  }

  setAboutLoad(val: number) {
    this.aboutLoad = val
  }

  setAboutSettings(val: IXAboutSettings) {
    this.aboutSettings = val
  }

  setIsAboutActive(val: boolean) {
    this.isAboutActive = val
  }

  setTransActive(val: boolean) {
    this.transActive = val
  }

  setIsSettingsInited(val: boolean) {
    this.isSettingsInited = val
  }

  setFooterData(val: any) {
    this.footerData = val
  }

  setTranses(val: any) {
    this.transes = val
  }

  setTranslations(val: any) {
    this.translations = val
  }

  setInterSet(index: any) {
    this.interSet = index
  }

  setHomeSliderSpeed(index: number) {
    this.homeSliderSpeed = index
  }

  setSliderInited(index: boolean) {
    this.sliderInited = index
  }

  setSliderIndex(index: number) {
    this.sliderIndex = index
  }

  setProjects(val: IXProject[]) {
    this.projects = val
  }

  setDragId(id: number) {
    this.dragId = id
  }

  setContacts(val: IXContacts | null) {
    this.contacts = val
  }

  setHomeImages(val: string[]) {
    this.homeImages = val
  }

  setMetaData(val: any) {
    this.metaData = val
  }

  setPages(val: any[]) {
    this.pages = val
  }

}