import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {FormattedMessage, useIntl} from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "..";
import useWindowDimensions from "../core/hooks/useWindowDimensions";
import {LOCALES} from "../core/i18n/locales";
import {stripPhone} from "../core/utils/utils";

import styles from "../styles/Footer.module.scss"

const Footer: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const {formatMessage: f} = useIntl()

  const [isVisible, setIsVisible] = useState(false)
  const [isToTopVisible, setIsToTopVisible] = useState(false)
  
  const toTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    })
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "", nodeName = event.target.nodeName.toLowerCase()

    if (nodeName == "img" || nodeName == "span") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, location.pathname, event);

    if (path == location.pathname) {
      return
    }

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      setTimeout(() => {
        navigate(path)
      }, 300)
    }, 100)
  }

  useEffect(() => {
    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop;
      if (scrollTop >= 80) {
        setIsToTopVisible(true);
      } else {
        setIsToTopVisible(false);
      }
    }

    setTimeout(() => {
      setIsVisible(prev => prev = true)
    }, 500);

    document.getElementById("root")!.addEventListener('scroll', onScroll);

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll);
    }
  }, [])

  return (
    <>
      {/*<div className={styles.to_top} onClick={() => toTop()} style={{opacity: isToTopVisible ? "1" : "0"}}><i className="far fa-chevron-up"></i></div>*/}
    
      <footer className={styles.footer}>
        <div className="container" style={{
          transition: "all 0.3s ease",
          opacity: isVisible ? "1" : "0"
        }}>
          <div className="row">
            <div className="col-12 col-md-12" style={{display: width > 768 ? "block" : "none"}}>
              <a href="/" className={styles.logo}><img src="/assets/images/logo_w.svg" /></a>
            </div>
            
            <div className={"row " + styles.btm}>
              <div className="col-12 col-md-4">
                <a className={styles.txt} href={adminStore.footerData.address_url} target="_blank">{store.locale == LOCALES.RUSSIAN ? adminStore.footerData.address : adminStore.footerData.address_en}</a>
              </div>
              <div className="col-12 col-md-4">
                <div className={styles.links}>
                  <div className={styles.l_block}>
                    <a className="hrefeds" href={`tel:${stripPhone(adminStore.contacts!.phone)}`}><span>{adminStore.contacts!.phone}</span></a>
                    <a className="hrefeds" href={`mailto:${adminStore.contacts!.email}`}><span>{adminStore.contacts!.email}</span></a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className={styles.links + " " + styles.lmob}>
                  <div className={styles.l_block}>
                    <a className="hrefeds" href="/projects"><span><FormattedMessage id="menu_projects" /></span></a>
                    <a className="hrefeds" href="/contacts"><span><FormattedMessage id="menu_contacts" /></span></a>
                  </div>
                  <div className={styles.l_block}>
                    <a className="hrefeds" href={adminStore.footerData.social1_url} target="_blank"><span>{store.locale == LOCALES.RUSSIAN ? adminStore.footerData.social1 : adminStore.footerData.social1_en}</span></a>
                    <a className="hrefeds" href={adminStore.footerData.social2_url} target="_blank"><span>{store.locale == LOCALES.RUSSIAN ? adminStore.footerData.social2 : adminStore.footerData.social2_en}</span></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className={styles.lined}>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <p className={styles.copys}>{store.locale == LOCALES.RUSSIAN ? adminStore.footerData.copys : adminStore.footerData.copys_en}</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <p style={width > 960 ? {
                      position: 'relative',
                      left: "-8px"
                    } : {}}>{store.locale == LOCALES.RUSSIAN ? adminStore.footerData.reqs : adminStore.footerData.reqs_en}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default observer(Footer)
