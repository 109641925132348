import { Menu, MenuProps } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {FormattedMessage} from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from ".."
import useWindowDimensions from "../core/hooks/useWindowDimensions"
import {LOCALES} from "../core/i18n/locales"
import { isNullOrUndefined, translit } from "../core/utils/utils"

import styles from "../styles/Header.module.scss"

interface HeaderProps {
  isMobile?: boolean
  onOpenMenu?: any
  goToSection?: any
  onBackCall?: any
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

// const items: MenuProps['items'] = [
//   getItem('Navigation Two', 'sub2', null, [
//     getItem('Option 5', '5'),
//     getItem('Option 6', '6'),
//   ]),

//   getItem('Navigation Three', 'sub4', null, [
//     getItem('Option 9', '9'),
//     getItem('Option 10', '10'),
//     getItem('Option 11', '11'),
//     getItem('Option 12', '12'),
//   ])
// ];

const Header: FC<HeaderProps> = ({
  onOpenMenu = null, goToSection = null, onBackCall = null, isMobile = false
}) => {

  const { store, adminStore } = useContext(Context)
  const { width, height } = useWindowDimensions()
  const navigate = useNavigate()
  const location = useLocation()

  const [headerToggled, setHeaderToggled] = useState(true)
  const [headerTgl, setHeaderTgl] = useState(false)

  const [isMenuShown, setIsMenuShown] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  const [items, setItems] = useState([] as MenuProps['items'])

  const openMenu = () => {
    setIsMenuShown(prev => prev = true)
    setIsMenuOpen(prev => prev = true)
    document.getElementById("root")!.style.overflowY = "hidden"
    document.getElementsByTagName("html")[0].style.overflowY = "hidden"
    document.body.style.overflowY = "hidden"
    setTimeout(() => {
      setIsMenuVisible(prev => prev = true);
    }, 100);
  }

  const closeMenu = () => {
    setIsMenuVisible(prev => prev = false)
    setIsMenuOpen(prev => prev = false)
    document.getElementById("root")!.style.overflowY = "unset"
    document.getElementsByTagName("html")[0].style.overflowY = "unset"
    document.body.style.overflowY = "unset"
    setTimeout(() => {
      setIsMenuShown(prev => prev = false);
    }, 300);
  }

  const goTop = () => {
    if (store.menuSelected != 0) {
      store.setIsHomeBlockVisible(false);
      store.setIsHeaderLocked(false);
      store.setSelectedSlide(0);
      setTimeout(() => {
        store.setMenuSelected(0);
        setTimeout(() => {
          store.setIsHomeBlockVisible(true);
        }, 100);
      }, 200);
    }

    const section = document.querySelector(`#top`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "";

    if (event.target.nodeName.toLowerCase() == "img") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, location.pathname, event);

    if (path == location.pathname) {
      return
    }

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      window.scroll(0, 0)

      setTimeout(() => {
        navigate(path)
      }, 300)
    }, 100)
  }

  useEffect(() => {
    let scrFix = 0;

    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop;
      if (scrollTop >= 40) {
        store.setIsHeaderLocked(true)
      } else {
        store.setIsHeaderLocked(false)
      }

      console.log(store.xScr)

      if (scrollTop < scrFix && scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = true)
      } else if (scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = false)
      }

      scrFix = parseInt(`${scrollTop}`);
      
      if (location.pathname.includes("/projects") && store.selectedProject != null) {
        if (scrollTop > window.innerHeight - 50) {
          setHeaderTgl(prev => prev = true)
        } else {
          setHeaderTgl(prev => prev = false)
        }
      } else {
        setHeaderTgl(prev => prev = false)
      }
    }
    
    document.getElementById("root")!.addEventListener('scroll', onScroll);

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll);
    }
  }, []);

  return (
    <>
      <div className={styles.mob_menu + (!headerToggled ? " " + styles.fixedd : "")} style={{
        display: isMenuShown ? "block" : "none",
        opacity: isMenuVisible ? "1" : "0"
      }}>
        <div className={styles.mm_wrap}>
          <div className={styles.mm_w_logo}>
            <a href="/"><img src="/assets/images/logo.svg" /></a>
          </div>
          <div className={styles.mm_w_items}>
            <a href="/projects" className={(location.pathname == "/projects" ? " " + styles.active : "")}><FormattedMessage id="menu_projects" /></a>
            {adminStore.isAboutActive ? <a href="/about" className={(location.pathname == "/about" ? " " + styles.active : "")}><FormattedMessage id="menu_about" /></a> : <></>}
            <a href="/contacts" className={(location.pathname == "/contacts" ? " " + styles.active : "")}><FormattedMessage id="menu_contacts" /></a>
          </div>
          <div className={styles.mm_w_contacts}>
            <a href="tel:+79164000404">+7 916 400 04 04</a>
            <a href="mailto:mainburo.office@gmail.com">mainburo.office@gmail.com</a>
          </div>
        </div>
      </div>

      <div className={styles.menu_open + (isMenuOpen ? " " + styles.openned : "") + (!headerToggled && !isMenuShown ? " " + styles.tgl : "")} onClick={() => isMenuShown ? closeMenu() : openMenu()}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="6" width="20" height="1" fill={location.pathname.includes("/projects") && !headerTgl && !isMenuShown && store.selectedProject != null ? "#fff" : "black"}/>
          <rect y="13" width="20" height="1" fill={location.pathname.includes("/projects") && !headerTgl && !isMenuShown && store.selectedProject != null ? "#fff" : "black"}/>
        </svg>
      </div>

      <header className={styles.header + (store.isHeaderLocked ? "" : " " + styles.noblur) + (!headerToggled && !isMenuShown ? " " + styles.fixed : "") + (headerTgl ? " " + styles.tgl : "") + (store.isHeaderLocked ? " " + styles.white : "") + (location.pathname.includes("/projects") && !headerTgl && store.selectedProject != null ? " " + styles.nfilled : "")}>
        <div className="container">
          <div className="row">
            <div className={"col-4 " + styles.centere} style={{display: width > 768 ? "none" : "block"}}>
              
            </div>
            <div className="col-4 col-md-4" style={{display: width > 768 ? "block" : "none"}}>
              <div className={styles.menu}>
                <a href="/projects" className={styles.menu_item}><span><FormattedMessage id="menu_projects" /></span></a>
                {adminStore.isAboutActive ? <a href="/about" className={styles.menu_item}><span><FormattedMessage id="menu_about" /></span></a> : <></>}
                <a href="/contacts" className={styles.menu_item}><span><FormattedMessage id="menu_contacts" /></span></a>
              </div>
            </div>
            <div className={(width > 768 ? "col-4 col-md-4 " : "col-4 ") + styles.centere}>
              <div className={styles.logo}><a href="/"><img src={!headerTgl && location.pathname.includes("/projects") && store.selectedProject != null ? `/assets/images/logo_w.svg` : `/assets/images/logo.svg`} /></a></div>
            </div>
            <div className={(width > 768 ? "col-4 col-md-4 " : "col-4 ") + styles.ending}>
              <div className={styles.langs + " langsx"} style={{display: adminStore.transActive ? "flex" : "none"}}>
                <div className={store.locale == LOCALES.ENGLISH ? styles.active : ""} onClick={() => store.setLocale(LOCALES.ENGLISH)}>En</div>
                <div className={store.locale == LOCALES.RUSSIAN ? styles.active : ""} onClick={() => store.setLocale(LOCALES.RUSSIAN)}>Ru</div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default observer(Header)