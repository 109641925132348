import { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import AppRouter from './components/AppRouter';
import { Context } from '.';
import useWindowDimensions from './core/hooks/useWindowDimensions';

import './styles/App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import { SliderToggleDirection, SliderToggleDir, StorageKeys } from './core/utils/defaults';

import ImageViewModal from "./components/ModalImageView"
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';
import ThemeSwitcher from './components/ThemeSwitcher';
import AdminManager from './pages/Admin/AdminManager';
import {useFetching} from './core/hooks/useFetching';
import {ApiService} from './core/api/api';
import {LOCALES} from './core/i18n/locales';
import {IntlProvider} from 'react-intl';

const App: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()
  
  const [userName, setUserName] = useState("")
  const [userPhone, setUserPhone] = useState("")
  
  const { width, height } = useWindowDimensions()

  const [fetchSettings, isSettingsLoading] = useFetching(async () => {
    const projsRestore = localStorage.getItem(StorageKeys.PROJECTS)
    if (projsRestore != null) {
      adminStore.setProjects(JSON.parse(projsRestore))

      const hiRest = localStorage.getItem(StorageKeys.SETTINGS_HOME_IMGS)
      const contRest = localStorage.getItem(StorageKeys.SETTINGS_CONTACTS)
      const hssRest = localStorage.getItem(StorageKeys.SETTINGS_HSS)
      const transRest = localStorage.getItem(StorageKeys.SETTINGS_TRANS)
      const transStRest = localStorage.getItem(StorageKeys.SETTINGS_TRANS_ST)
      const footerDtRest = localStorage.getItem(StorageKeys.SETTINGS_FOOTER_DATA)
      const aboutDtRest = localStorage.getItem(StorageKeys.SETTINGS_ABOUT)
      const seoDtRest = localStorage.getItem(StorageKeys.SETTINGS_SEO)

      if (seoDtRest != null && footerDtRest != null && hiRest != null && contRest != null && hssRest != null && transRest != null && transStRest != null && aboutDtRest != null) {
        adminStore.setFooterData(JSON.parse(footerDtRest))
        adminStore.setAboutSettings(JSON.parse(aboutDtRest))
        adminStore.setSeoConfig(JSON.parse(seoDtRest))
        adminStore.setHomeImages(JSON.parse(hiRest))
        adminStore.setContacts(JSON.parse(contRest))
        adminStore.setHomeSliderSpeed(parseInt(hssRest))
        adminStore.setTranslations(parseTranslations(JSON.parse(transRest))) 

        const asdt = JSON.parse(aboutDtRest)
        adminStore.setIsAboutActive(asdt.status)

        if (transStRest == "true") {
          adminStore.setTransActive(true)
          const ext = localStorage.getItem("mainburo_lang")
          if (ext != null && ext != "") {
            store.setLocale(ext)
          } else {
            store.setLocale(LOCALES.RUSSIAN)
          }
        } else {
          store.setLocale(LOCALES.RUSSIAN)
        }
      }
    }
    
    const projsRes = await ApiService.projects.getList()
    const res = await ApiService.core.getSettings()

    console.log(res, projsRes)

    const settings: any[] = res.data.settings;
    const contacts = settings.find(v => v.name == "contacts")
    const home_images = settings.find(v => v.name == "home_images")
    const hss = settings.find(v => v.name == "home_slider_speed")
    const trans = settings.find(v => v.name == "translations")
    const transSt = settings.find(v => v.name == "trans_state")
    const footerDt = settings.find(v => v.name == "footer_settings")
    const aboutDt = settings.find(v => v.name == "about")
    const seoDt = settings.find(v => v.name == "seo_config")

    const projects = projsRes.data.projects

    localStorage.setItem(StorageKeys.SETTINGS_HOME_IMGS, JSON.stringify(home_images.data))
    localStorage.setItem(StorageKeys.SETTINGS_CONTACTS, JSON.stringify(contacts.data))
    localStorage.setItem(StorageKeys.SETTINGS_HSS, `${hss.data.value}`)
    localStorage.setItem(StorageKeys.SETTINGS_TRANS, JSON.stringify(trans.data))
    localStorage.setItem(StorageKeys.SETTINGS_TRANS_ST, `${transSt.data.value}`)
    localStorage.setItem(StorageKeys.PROJECTS, JSON.stringify(projects))
    localStorage.setItem(StorageKeys.SETTINGS_FOOTER_DATA, JSON.stringify(footerDt.data))
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(aboutDt.data))
    localStorage.setItem(StorageKeys.SETTINGS_SEO, JSON.stringify(seoDt.data))

    if (transSt.data.value == true) {
      adminStore.setTransActive(true)
      const ext = localStorage.getItem("mainburo_lang")
      if (ext != null && ext != "") {
        store.setLocale(ext)
      } else {
        store.setLocale(LOCALES.RUSSIAN)
      }
    } else {
      store.setLocale(LOCALES.RUSSIAN)
    }

    adminStore.setTranslations(parseTranslations(trans.data))
    adminStore.setTranses(trans.data)
    adminStore.setFooterData(footerDt.data)
    adminStore.setAboutSettings(aboutDt.data)
    adminStore.setSeoConfig(seoDt.data)

    adminStore.setContacts(contacts.data)
    adminStore.setHomeImages(home_images.data)
    adminStore.setProjects(projects)
    adminStore.setHomeSliderSpeed(hss.data.value)

    adminStore.setIsSettingsInited(true)
  })

  const parseTranslations = (data: any[]) => {
    let newData: any = {
      [LOCALES.RUSSIAN]: {},
      [LOCALES.ENGLISH]: {}
    }

    data.forEach((element) => {
      newData[LOCALES.RUSSIAN][element.name] = element.value
      newData[LOCALES.ENGLISH][element.name] = element.value_en
    })

    console.log(newData)

    return newData
  }

  const detectWindows = () => {
    let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    if (windowsPlatforms.indexOf(window.navigator.platform) !== -1) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }
  }, [width, height]);

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }

    setTimeout(() => {
      store.setIsFaderShown(false)
      setTimeout(() => {
        store.setIsFaderVisible(false)
      }, 400)
    }, 1300)

    store.checkAuth()

    fetchSettings()

    if (detectWindows()) {
      // document.body.classList.add("win-upscale")
      document.getElementsByTagName("html")[0].classList.add("win-upscale")
    }

    //console.log(JSON.stringify(roomsCompiled))
  }, []);


  return (
    location.pathname != "/admin/auth" ? (adminStore.isSettingsInited ? <>
      <IntlProvider messages={adminStore.translations[store.locale]} locale={store.locale} defaultLocale={LOCALES.RUSSIAN}>
        <div className="fader" style={{display: store.isFaderVisible ? "block" : "none", opacity: store.isFaderShown ? "1" : "0"}}></div>

        {adminStore.seoConfig != null ? <Helmet>
          <title>{store.locale == LOCALES.RUSSIAN ? adminStore.seoConfig.title : adminStore.seoConfig.title_en}</title>
          <meta property="og:title" content={store.locale == LOCALES.RUSSIAN ? adminStore.seoConfig.title : adminStore.seoConfig.title_en} />
          <meta name="description" content={store.locale == LOCALES.RUSSIAN ? adminStore.seoConfig.desc : adminStore.seoConfig.desc_en} />
          <meta property="og:description" content={store.locale == LOCALES.RUSSIAN ? adminStore.seoConfig.desc : adminStore.seoConfig.desc_en} />
          <link rel="canonical" href={`https://mainburo.com`} />
        </Helmet> : <></>}

        <Header />  

        <AppRouter />

        <Footer />

        {store.isAuth ? <AdminManager /> : <></>}

        <ImageViewModal
          visible={store.isImageViewVisible}
          images={store.selectedImages}
          selectedIndex={store.selectedImageIndex}
          onSelectImage={(index) => {
            store.setSelectedImageIndex(index)
          }}
          onToggleImage={(dir: SliderToggleDirection) => {
            if (dir == SliderToggleDir.NEXT) {
              if (store.selectedImageIndex < (store.selectedImages.length - 1)) {
                store.setSelectedImageIndex(store.selectedImageIndex + 1)
              } else {
                store.setSelectedImageIndex(0)
              }
            } else {
              if (store.selectedImageIndex > 0) {
                store.setSelectedImageIndex(store.selectedImageIndex - 1)
              } else {
                store.setSelectedImageIndex(store.selectedImages.length - 1)
              }
            }
          }}
          onClose={() => store.setIsImageViewVisible(false)} />
      </IntlProvider>
    </> : <></>) : <><AppRouter /></>
  )
}

export default observer(App)
