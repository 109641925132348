import {message, Switch, Upload, UploadProps} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import {ApiService} from "../../../core/api/api"
import {PromoSale} from "../../../core/models/PromoSale"
import {API_URL, StorageKeys} from "../../../core/utils/defaults"
import {asyncForEach, translit} from "../../../core/utils/utils"
import imageCompression from "browser-image-compression"
import { Editor } from "@tinymce/tinymce-react"

import { DeleteOutlined, EditOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons"

import styles from "../../../styles/AdminManager.module.scss"
import Modal from "../../../components/Modal"
import {useFetching} from "../../../core/hooks/useFetching"
import {ProjectsAll} from "../../../core/utils/projects"
import {IXImg, IXProject} from "../../../core/models/XProject"

import { ReactSortable } from "react-sortablejs";
import {RcFile} from "antd/es/upload"

const ProjectsManager: FC = () => {
  
  const { store, adminStore } = useContext(Context)
  const location = useLocation()

  const [actualProjs, setActualProjs] = useState([] as any[])
  const [offProjs, setOffProjs] = useState([] as any[])

  const [activeTab, setActiveTab] = useState(1)
  const [activeTabEd, setActiveTabEd] = useState(1)

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isDeleteImgVisible, setIsDeleteImgVisible] = useState(false)
  
  const [selectedImg, setSelectedImg] = useState(0)

  const [projImage, setProjImage] = useState("")
  const [projImageList, setProjImageList] = useState([] as string[])
  const [projImageListMp, setProjImageListMp] = useState([] as any[])
  const [projImages, setProjImages] = useState([] as IXImg[])

  const [projSpeed, setProjSpeed] = useState("")
  const [projTitle, setProjTitle] = useState("")
  const [projTitleEn, setProjTitleEn] = useState("")
  const [projFullname, setProjFullname] = useState("")
  const [projAddress, setProjAddress] = useState("")
  const [projAddressFull, setProjAddressFull] = useState("")
  const [projAddressFullEn, setProjAddressFullEn] = useState("")
  const [projAddressFull2, setProjAddressFull2] = useState("")
  const [projCategory, setProjCategory] = useState("")
  const [projCategoryEn, setProjCategoryEn] = useState("")
  const [projYear, setProjYear] = useState("")
  const [projArea, setProjArea] = useState("")
  const [projType, setProjType] = useState("")
  const [projAbout, setProjAbout] = useState("")

  const [contentTitle, setContentTitle] = useState("")
  const [contentTitleEn, setContentTitleEn] = useState("")
  const [contentText, setContentText] = useState("")
  const [contentTextEn, setContentTextEn] = useState("")

  const [contentType, setContentType] = useState("text")
  const [contentBlocks, setContentBlocks] = useState([] as any[])
  const [contentAlign, setContentAlign] = useState("")
  const [contentIndex, setContentIndex] = useState(0)
  const [contentItem, setContentItem] = useState(null as any | null)
  const [isContentDelete, setIsContentDelete] = useState(false)
  const [isContentEdit, setIsContentEdit] = useState(false)
  const [isContentAdd, setIsContentAdd] = useState(false)
  
  const [projStatusStr, setProjStatusStr] = useState("")
  const [projStatusStrEn, setProjStatusStrEn] = useState("")
  const [projStatus, setProjStatus] = useState(0)

  const [selectedProj, setSelectedProj] = useState(null as null | IXProject)

  const editorRef = useRef(null as any)

  const props3: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif")) {
        return file
      } else {
        const options = { maxSizeMB: 2.5, maxWidthOrHeight: 2720, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onChange: async (info) => {
      setIsDataLoading(prev => prev = false)
      const { status } = info.file;
      if (status === 'done') {
        message.success(`Файл успешно загружен!`);
        console.log(info.file.response)
        setProjImageList(prev => prev = [...prev, info.file.response.urls[0]])
        setProjImageListMp(prev => prev = [...prev, {id: 0, url: info.file.response.urls[0]}])
        setProjImage(prev => prev = `${prev};${info.file.response.urls[0]}`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const props34: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file: RcFile, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif")) {
        return file
      } else {
        const options = { maxSizeMB: 2.5, maxWidthOrHeight: 2720, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const [fetchProjects] = useFetching(async () => {
    const res = await ApiService.projects.getList()

    console.log(res)

    let projs: IXProject[] = res.data.projects

    projs = projs.sort((a, b) => {
      if (a.index! > b.index!) {
        return 1
      } else if (a.index! < b.index!) {
        return -1
      } else {
        return 0
      }
    })

    adminStore.setProjects(projs)
    setActualProjs(prev => prev = projs.filter((v: any) => v.status == 1))
    setOffProjs(prev => prev = projs.filter((v: any) => v.status == 0))

    store.setProjectTilts(2)
  })

  const selectProject = (proj: IXProject) => {
    setProjImage(proj.image!)
    setProjImageList(proj.image != "" ? proj.image!.split(";") : [])
    setProjImages(proj.images!)

    setProjSpeed(proj.speed!)
    setProjTitle(proj.title!)
    setProjTitleEn(proj.title_en!)
    setProjFullname(proj.fullname!)
    setProjAddress(proj.address!)
    setProjAddressFull(proj.address_full!)
    setProjAddressFullEn(proj.address_full_en!)
    setProjAddressFull2(proj.address_full2!)
    setProjCategory(proj.category!)
    setProjCategoryEn(proj.category_en!)
    setProjYear(`${proj.year}`)
    setProjArea(`${proj.area_str}`)
    setProjType(proj.type!)
    setProjAbout(proj.about!)

    setProjStatus(proj.status!)
    setProjStatusStr(proj.status_str!)
    setProjStatusStrEn(proj.status_str_en!)

    setSelectedProj(prev => prev = proj)
  }

  const addNewSaleAction = async () => {
    setIsDataLoading(prev => prev = true)

    const res = await ApiService.projects.create({
      index: adminStore.projects.length + 1,
      title: "Не заполнено",
      fullname: "",
      address: "",
      address_full: "",
      address_full2: "Не заполнено",
      year: 2023,
      category: "",
      area_str: "0 м",
      image: "",
      images: [],
      about: "",
      status: 0,
      status_str: "",
      type: ""
    })

    console.log(res)

    fetchProjects()

    setActiveTab(prev => prev = 2)

    message.success("Новый проект успешно добавлен!")
    setIsDataLoading(prev => prev = false)
  }

  const deleteImgAction = async () => {
    setIsDeleteImgVisible(false)
    setProjImageList(prev => prev = prev.filter((v, ix) => ix != selectedImg))
    setSelectedImg(prev => prev = 0)
  }

  const saveSaleAction = async () => {
    setIsDataLoading(prev => prev = true)

    let newItem: any = {
      image: projImageList.join(";"),
      images: projImages,
      title: projTitle,
      title_en: projTitleEn,
      speed: projSpeed,
      fullname: projFullname,
      address: projAddress,
      address_full: projAddressFull,
      address_full_en: projAddressFullEn,
      address_full2: projAddressFull2,
      category: projCategory,
      category_en: projCategoryEn,
      year: projYear == "" ? "0" : projYear,
      area_str: projArea == "" ? "0 м" : projArea,
      type: projType,
      about: projAbout,
      status: projStatus,
      status_str: projStatusStr,
      status_str_en: projStatusStrEn
    }

    const res = await ApiService.projects.update(selectedProj!.id!, newItem)

    fetchProjects()

    let newArr: any[] = [
      ...adminStore.projects.filter(v => v.id != selectedProj!.id!),
      newItem
    ]
    adminStore.setProjects(newArr)
    localStorage.setItem(StorageKeys.PROJECTS, JSON.stringify(newArr))

    message.success("Проект успешно обновлен!")
    setIsDataLoading(prev => prev = false)
  }

  const deleteSaleAction = async () => {
    setIsDeleteVisible(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    const res = await ApiService.projects.delete(selectedProj!.id!)

    setSelectedProj(prev => prev = null)

    fetchProjects()

    let newArr: any[] = [
      ...adminStore.projects.filter(v => v.id != selectedProj!.id!)
    ]
    adminStore.setProjects(newArr)
    localStorage.setItem(StorageKeys.PROJECTS, JSON.stringify(newArr))

    message.success("Проект успешно удален!")
    setIsDataLoading(prev => prev = false)
  }

  const saveProjects = async () => {

    console.log(actualProjs, offProjs)

    let actPr = actualProjs.map((item, ix) => {
      return {...item, index: ix}
    })

    await asyncForEach(actPr, async (item: IXProject) => {
      const res = await ApiService.projects.update(item.id!, {
        index: item.index
      })

      console.log(res)
    })

    fetchProjects()

    message.success("Расположение проектов успешно обновлено!");
  }

  const handleOver = (ev: any) => {
    ev.preventDefault();
  }

  const handleDrag = (ev: any) => {
    if (ev.currentTarget != undefined) {
      adminStore.setDragId(parseInt(ev.currentTarget.id));
    }
  }

  const handleImOver = (ev: any) => {
    ev.preventDefault();
  }

  const handleImDrag = (ev: any) => {
    if (ev.currentTarget != undefined) {
      adminStore.setDragId(parseInt(ev.currentTarget.id));
    }
  }

  const handleImgOver = (ev: any) => {
    ev.preventDefault();
  }

  const handleImgDrag = (ev: any) => {
    if (ev.currentTarget != undefined) {
      adminStore.setDragId(parseInt(ev.currentTarget.id));
    }
  }

  const handleImgDrop = async (ev: any) => {
    ev.preventDefault();
    const dragItem = projImageList.findIndex((item: any, ix) => ix == adminStore.dragId);
    const dropItem = projImageList.findIndex(
      (item: any, ix) => ix == parseInt(ev.currentTarget.id)
    );
    
    const arr = moveImgItem(dragItem! - 1, dropItem! - 1)

    console.log(arr)

    const res = await ApiService.projects.update(selectedProj!.id!, {
      image: arr.join(";")
    })

    setProjImageList(prev => prev = arr)

    fetchProjects()

    message.success("Расположение проектов успешно обновлено!");
  }

  const updateBlocks = async (ns: any[]) => {
    setSelectedProj(prev => prev = {...prev, images: ns})
    setProjImages(prev => prev = ns)
    const res = await ApiService.projects.update(selectedProj!.id!, {
      images: ns
    })
    fetchProjects()
    message.success("Расположение блоков успешно обновлено!")
  }

  const handleImDrop = async (ev: any) => {
    ev.preventDefault();
    const dragItem = selectedProj!.images!.findIndex((item: any, ix) => ix == adminStore.dragId);
    const dropItem = selectedProj!.images!.findIndex(
      (item: any, ix) => ix == parseInt(ev.currentTarget.id)
    );
    
    const arr = moveImItem(dragItem! - 1, dropItem! - 1)

    console.log(arr)

    const res = await ApiService.projects.update(selectedProj!.id!, {
      images: arr
    })

    setProjImages(prev => prev = arr)
    setSelectedProj(prev => prev = {...prev, images: arr})

    fetchProjects()

    message.success("Расположение проектов успешно обновлено!");
  }

  const handleDrop = async (ev: any) => {
    ev.preventDefault();
    const dragItem = (activeTab == 1 ? actualProjs : offProjs).find((item: any) => item.index == adminStore.dragId);
    const dropItem = (activeTab == 1 ? actualProjs : offProjs).find(
      (item: any) => item.index == parseInt(ev.currentTarget.id)
    );
    
    const arr = moveItem(dragItem!.index! - 1, dropItem!.index! - 1)

    console.log(arr)

    await asyncForEach(arr, async (item: IXProject) => {
      const res = await ApiService.projects.update(item.id!, {
        index: item.index
      })

      console.log(res)
    })

    fetchProjects()

    message.success("Расположение проектов успешно обновлено!");
  }

  const moveImgItem = (from: number, to: number) => {
    const f = projImageList.findIndex((v: any, ix) => ix == (from + 1));
    const t = projImageList.findIndex((v: any, ix) => ix == (to + 1));
    let slds: any[] = [];

    projImageList.forEach((itm: any, ix: number) => {
      if (ix == f) {
        slds.push(projImageList[t])
      } else if (ix == t) {
        slds.push(projImageList[f])
      } else {
        slds.push(itm)
      }
    })

    return slds
  }

  const moveImItem = (from: number, to: number) => {
    const f = selectedProj!.images!.findIndex((v: any, ix) => ix == (from + 1));
    const t = selectedProj!.images!.findIndex((v: any, ix) => ix == (to + 1));
    let slds: any[] = [];

    selectedProj!.images!.forEach((itm: any, ix: number) => {
      if (ix == f) {
        slds.push(selectedProj!.images![t])
      } else if (ix == t) {
        slds.push(selectedProj!.images![f])
      } else {
        slds.push(itm)
      }
    })

    return slds
  }

  const moveItem = (from: number, to: number) => {
    const f = adminStore.projects.findIndex((v: any) => v.index == (from + 1));
    const t = adminStore.projects.findIndex((v: any) => v.index == (to + 1));
    let slds: any[] = [];

    const np = t - 1
    
    let pslds = adminStore.projects.sort((a,b) => a.index! > b.index! ? 1 : (a.index! < b.index! ? -1 : 0))

    pslds.forEach((itm: any, ix: number) => {

      console.log(ix, f, t)

      if (ix == f) {
        slds.push({...itm, index: t})
      } else if (ix == t) {
        slds.push({...itm, index: t + 2})
      } else if (ix > t) {
        slds.push({...itm, index: itm.index + 1})
      } else if (ix < t) {
        slds.push({...itm, index: itm.index - 1})
      } else {
        slds.push(itm)
      }
      
      // if (ix == f) {
      //   slds.push({...itm, index: t})
      // } else if (ix == t) {
      //   slds.push({...itm, index: f})
      // } else {
      //   slds.push(itm)
      // }
    })

    slds = slds.sort((a,b) => a.index > b.index ? 1 : (a.index < b.index ? -1 : 0))

    slds.forEach((el, ixx) => {
      el.index = ixx
    })

    console.log(slds)

    return slds
  }

  const addContentBlock = async () => {
    setIsContentAdd(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let newBlocks: any[] = [...selectedProj!.images!, {
      type: contentType,
      align: contentAlign,
      blocks: contentBlocks,
      ...(contentType == "text" ? {
        title: contentTitle,
        title_en: contentTitleEn,
        text_en: contentTextEn,
        text: contentText
      } : {})
    }]

    const res = await ApiService.projects.update(selectedProj!.id!, {
      images: newBlocks
    })

    setSelectedProj(prev => {
      let newItem: any = {...prev}
      newItem.images = newBlocks
      return prev = newItem
    })
    setProjImages(prev => prev = newBlocks)

    message.success("Блок успешно добавлен!")

    setIsDataLoading(prev => prev = false)
  }

  const saveContentBlock = async () => {
    setIsContentEdit(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let newBlocks: any[] = []
    selectedProj!.images!.forEach((item: any, ix) => {
      if (ix == contentIndex) {
        newBlocks.push({
          ...item, 
          align: contentAlign, 
          blocks: contentBlocks,
          ...(contentType == "text" ? {
            title: contentTitle,
            title_en: contentTitleEn,
            text_en: contentTextEn,
            text: contentText
          } : {})
        })
      } else {
        newBlocks.push(item)
      }
    })

    const res = await ApiService.projects.update(selectedProj!.id!, {
      images: newBlocks
    })

    setSelectedProj(prev => {
      let newItem: any = {...prev}
      newItem.images = newBlocks
      return prev = newItem
    })
    setProjImages(prev => prev = newBlocks)

    message.success("Блок успешно обновлен!")

    setIsDataLoading(prev => prev = false)
  }

  const deleteContentBlock = async () => {
    setIsContentDelete(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let newBlocks: any[] = []
    selectedProj!.images!.forEach((item: any, ix) => {
      if (ix != contentIndex) {
        newBlocks.push(item)
      }
    })

    const res = await ApiService.projects.update(selectedProj!.id!, {
      images: newBlocks
    })

    setSelectedProj(prev => {
      let newItem: any = {...prev}
      newItem.images = newBlocks
      return prev = newItem
    })
    setProjImages(prev => prev = newBlocks)

    message.success("Блок успешно удален!")

    setIsDataLoading(prev => prev = false)
  }

  const getName = (name: string) => {
    if (name == "three") {
      return "Три изображения"
    } else if (name == "two") {
      return "Два изображения"
    } else if (name == "one") {
      return "Одно изображение"
    } else if (name == "threemulti") {
      return "Три изображения, разная высота"
    } else if (name == "text") {
      return "Текстовый блок"
    }
  }

  useEffect(() => {
    store.setProjectTilts(store.projectTilts + 1)
  }, [actualProjs])

  useEffect(() => {
    store.setProjectTilts(0)
    setTimeout(() => {
      fetchProjects()
    }, 200);
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.grid_block}>
              <h3><span className="nomore" style={{
                display: selectedProj != null ? "inline-block" : "none"
              }} onClick={() => setSelectedProj(prev => prev = null)}><i className="far fa-arrow-left-long"></i> </span>{selectedProj == null ? "Список проектов" : "Редактирование проекта"}</h3>
              
              {selectedProj == null ? <>
                <div className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>Активные</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>Отключенные</div>
                </div>

                <div className={styles.xactions}>
                  <div className={styles.xactions_item + " " + styles.success + (store.projectTilts < 4 ? " " + styles.disabled : "")} onClick={() => saveProjects()}>Сохранить</div>
                  <div className={styles.xactions_item} onClick={() => addNewSaleAction()}>Добавить проект</div>
                </div>
                
                {activeTab == 1 ? <p className={styles.xdesc}>Вы можете менять расположение проектов.<br/>Для этого зажмите проект и перенесите на нужное место, взамен другого.</p> : <></>}
                  
                {activeTab == 1 ? <div>
                  <ReactSortable className={styles.xgrid} list={activeTab == 1 ? actualProjs : offProjs} setList={activeTab == 1 ? setActualProjs : setOffProjs}>
                    {actualProjs.map((item, ix) => <div className={styles.xgrid_item}>
                      <div 
                        className={styles.xcard} 
                        onClick={() => selectProject(item)}
                        draggable={true}
                        key={ix}
                        id={`${item.id}`}>
                        <div className={styles.xcard_image} style={{backgroundImage: `url(${item.image != null && item.image != "" ? item.image.split(";")[0] : ""})`}}></div>
                        <div className={styles.xcard_data}>
                          <div className={styles.xcard_data__title} dangerouslySetInnerHTML={{__html: `${item.title}`}}></div>
                          <div className={styles.xcard_data__st} dangerouslySetInnerHTML={{__html: `${item.category}`}}></div>
                        </div>
                        <div className={styles.xcard_actions}></div>
                      </div>
                    </div>)}
                  </ReactSortable>
                </div> : <div className={styles.xgrid}>
                  {offProjs.map((item, ix) => <div className={styles.xgrid_item}>
                    <div 
                      className={styles.xcard} 
                      onClick={() => selectProject(item)}
                      draggable={false}
                      key={ix}
                      id={`${item.id}`}>
                      <div className={styles.xcard_image} style={{backgroundImage: `url(${item.image != null && item.image != "" ? item.image.split(";")[0] : ""})`}}></div>
                      <div className={styles.xcard_data}>
                        <div className={styles.xcard_data__title} dangerouslySetInnerHTML={{__html: `${item.title}`}}></div>
                        <div className={styles.xcard_data__st} dangerouslySetInnerHTML={{__html: `${item.category}`}}></div>
                      </div>
                      <div className={styles.xcard_actions}></div>
                    </div>
                  </div>)}
                </div>}
              </> : <>
                <div className={styles.xactions}>
                  {activeTabEd == 2 ? <div className={styles.xactions_item} onClick={() => {
                    setContentType("text")
                    setContentTitle("")
                    setContentTitleEn("")
                    setContentText("")
                    setContentTextEn("")
                    setContentAlign("no")
                    setContentIndex(0)
                    setContentBlocks([{image: "", text: ""}, {image: "", text: ""}, {image: "", text: ""}])
                    setContentItem(null)
                    setIsContentAdd(prev => prev = true)
                  }}>Добавить блок</div> : <></>}
                  {activeTabEd == 1 ? <div className={styles.xactions_item + " " + styles.success} onClick={() => saveSaleAction()}>Сохранить</div> : <></>}
                  {activeTabEd == 1 ? <div className={styles.xactions_item + " " + styles.danger} onClick={() => setIsDeleteVisible(true)}>Удалить</div> : <></>}
                </div>

                <div className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTabEd}`]}>
                  <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 1)}>Основное</div>
                  <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 2)}>Контент</div>
                </div>
                
                {activeTabEd == 1 ? <div className="row">
                  <div className="col-12 col-md-8">
                    <div className={styles.xform}>
                      
                      <div className={styles.xform_input}>
                        <label>Статус</label>
                        <Switch defaultChecked={projStatus == 1} checked={projStatus == 1} onChange={(event) => {
                          if (projImageList.length == 0) {
                            message.error("Для начала загрузите изображения!")
                            setProjStatus(prev => prev = 0)
                          } else {
                            setProjStatus(prev => prev = event ? 1 : 0)
                          }
                        }} /> {projStatus == 1 ? "Активен" : "Отключен"}
                      </div>

                      <div style={{position: "relative", paddingBottom: "16px", borderBottom: "1px solid #ececec", marginBottom: "16px"}}>
                        <h3>Изображения</h3>

                        <div className={styles.xactions} style={{top: "-10px"}}>
                          <Upload {...props3}><div className={styles.xactions_item + " " + styles.successs}>Добавить изображение</div></Upload>
                        </div>

                        <p className={styles.xdesc}>Вы можете менять расположение изображений.<br/>Для этого зажмите картинку и перенесите на нужное место, взамен другой.<br/><b>Первое изображение всегда будет главным.</b><br/>{projImageList.length == 0 ? <b><small style={{color: "red"}}>Без изображений, проект не будет отображаться на странице проектов.</small></b> : <></>}</p>
                            
                        {/*<ReactSortable className={styles.flexed_imgs} list={projImageList} setList={(ns: any[], sortable, store) => {
                          console.log(store.dragging)
                          if (store.dragging != null) {
                            updateBlocks(ns)
                          }
                        }}>
                          
                      </ReactSortable>*/}

                        <div className={styles.flexed_imgs}>
                          {projImageList.map((image, ix) => <div 
                            draggable={true}
                            key={ix}
                            id={`${ix}`}
                            onDragOver={(e) => handleImgOver(e)}
                            onDragStart={(e) => handleImgDrag(e)}
                            onDrop={(e) => handleImgDrop(e)}
                            style={{backgroundImage: `url(${image})`}}>
                            <DeleteOutlined rev={null} onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()

                              setSelectedImg(ix)
                              setIsDeleteImgVisible(true)
                            }} />
                          </div>)}
                        </div>

                        <div className={styles.xform_input} style={{
                          marginBottom: "4px",
                          marginTop: "8px"
                        }}>
                          <label htmlFor="proj_spd">Скорость смены слайдов</label>
                          <input type="number" id="proj_spd" placeholder="Скорость смены слайдов" value={projSpeed} onChange={(event) => {
                            if (event.target.value.match(/^\d+$/)) {
                              setProjSpeed(prev => prev = event.target.value)
                            }
                          }} />
                        </div>
                      </div>
                        
                      <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="proj_ttl">Заголовок <small style={{color: "red"}}>(обязательно)</small></label>
                          <input type="text" id="proj_ttl" placeholder="Заголовок" value={projTitle} onChange={(event) => setProjTitle(prev => prev = event.target.value)} />
                        </div>
                      </div>

                      {adminStore.transActive ? <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="proj_ttl_en">Заголовок (англ.)</label>
                          <input type="text" id="proj_ttl_en" placeholder="Заголовок (англ.)" value={projTitleEn} onChange={(event) => setProjTitleEn(prev => prev = event.target.value)} />
                        </div>
                      </div> : <></>}

                      <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="proj_addr">Адрес</label>
                          <input type="text" id="proj_addr" placeholder="Адрес" value={projAddressFull} onChange={(event) => setProjAddressFull(prev => prev = event.target.value)} />
                        </div>
                      </div>

                      {adminStore.transActive ? <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="proj_addr_en">Адрес (англ.)</label>
                          <input type="text" id="proj_addr_en" placeholder="Адрес (англ.)" value={projAddressFullEn} onChange={(event) => setProjAddressFullEn(prev => prev = event.target.value)} />
                        </div>
                      </div> : <></>}

                      <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="proj_year">Год</label>
                          <input type="number" min={0} id="proj_year" placeholder="Год" value={projYear} onChange={(event) => {
                            if (event.target.value.match(/^\d+$/) || event.target.value == "") {
                              setProjYear(prev => prev = event.target.value)
                            }
                          }} />
                        </div>
                        <div className={styles.xform_input} style={{minWidth: "30%"}}>
                          <label htmlFor="proj_st_p">Статус проекта</label>
                          <input type="text" id="proj_st_p" placeholder="Статус проекта" value={projStatusStr} onChange={(event) => setProjStatusStr(prev => prev = event.target.value)} />
                        </div>
                        {adminStore.transActive ? <div className={styles.xform_input} style={{minWidth: "30%"}}>
                          <label htmlFor="proj_st_p_en">Статус проекта (англ.)</label>
                          <input type="text" id="proj_st_p_en" placeholder="Статус проекта (англ.)" value={projStatusStrEn} onChange={(event) => setProjStatusStrEn(prev => prev = event.target.value)} />
                        </div> : <></>}
                      </div>

                      <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="proj_area">Площадь</label>
                          <input type="text" id="proj_area" placeholder="Площадь" value={projArea} onChange={(event) => {
                            // if (event.target.value.match(/^\d+$/) || event.target.value == "") {
                            //   setProjArea(prev => prev = event.target.value)
                            // }

                            setProjArea(prev => prev = event.target.value)
                          }} />
                        </div>
                        <div className={styles.xform_input}>
                          <label htmlFor="proj_cat">Категория</label>
                          <input type="text" id="proj_cat" placeholder="Категория" value={projCategory} onChange={(event) => setProjCategory(prev => prev = event.target.value)} />
                        </div>
                        {adminStore.transActive ? <div className={styles.xform_input} style={{minWidth: "30%"}}>
                          <label htmlFor="proj_cat_en">Категория (англ.)</label>
                          <input type="text" id="proj_cat_en" placeholder="Категория (англ.)" value={projCategoryEn} onChange={(event) => setProjCategoryEn(prev => prev = event.target.value)} />
                        </div> : <></>}
                      </div>

                      {/**<div className={styles.xform_input}>
                        <label htmlFor="proj_about">Описание</label>
                        <textarea rows={5} id="proj_about" placeholder="Описание" value={projAbout} onChange={(event) => setProjAbout(prev => prev = event.target.value)}></textarea>
                      </div> */}
                    
                    </div>
                  </div>
                </div> : <div className="row">
                  <div className="col-12 col-md-12">
                    <div className={styles.xform} style={{position: "relative"}}>

                      {selectedProj.images!.length == 0 ? <p className={styles.descript}>Блоки отсутствуют... <a href="#" onClick={(evt) => {
                        evt.preventDefault()
                        evt.stopPropagation()

                        setContentType("text")
                    setContentTitle("")
                    setContentTitleEn("")
                    setContentText("")
                    setContentTextEn("")
                    setContentAlign("no")
                    setContentIndex(0)
                    setContentBlocks([{image: "", text: ""}, {image: "", text: ""}, {image: "", text: ""}])
                    setContentItem(null)
                    setIsContentAdd(prev => prev = true)
                      }}>Добавьте</a> первый блок в проекте!</p> : <></>}

                      <ReactSortable className={styles.xgrid} list={selectedProj.images} setList={(ns: any[], sortable, store) => {
                        console.log(store.dragging)
                        if (store.dragging != null) {
                          updateBlocks(ns)
                        }
                      }}>
                        {selectedProj != null ? selectedProj.images!.map((item, ix: number) => <div 
                          draggable={true}
                          key={ix}
                          id={`${ix}`}
                          className={styles.images_bl}>
                          <div className={styles.images_bl__edit} onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()

                            setContentTitle(item.title)
                            setContentTitleEn(item.title_en)
                            setContentText(item.text)
                            setContentTextEn(item.text_en)
                            setContentType(item.type)
                            setContentBlocks(item.blocks)
                            setContentAlign(item.align)
                            setContentIndex(ix)
                            setContentItem(item)
                            setIsContentEdit(prev => prev = true)
                          }}><EditOutlined rev={null} /></div>
                          <div className={styles.images_bl__del} onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()

                            setContentIndex(ix)
                            setContentItem(item)
                            setIsContentDelete(prev => prev = true)
                          }}><DeleteOutlined rev={null} /></div>
                          <div className={styles.images_bl__type}>{getName(item.type)}</div>
                          {item.type != "text" ? <div className={styles.images_bl__images}>
                            {item.blocks.map((im: any) => <div style={{backgroundImage: `url(${im.image})`}}></div>)}
                          </div> : <div className={styles.txt}>
                            <div>{item.title}</div>
                            <div>{item.text}</div>
                          </div>}
                        </div>) : <></>}
                      </ReactSortable>

                    </div>
                  </div>
                </div>}
              </>}

            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={isContentAdd}
        title="Добавить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
            
                <div className={styles.xform_input}>
                  <label htmlFor="bl_type">Тип блока</label>
                  <span className={styles.selc}>
                    <select name="bl_type" id="bl_type" value={contentType} onChange={(event) => {
                      setContentType(prev => prev = event.target.value)
                    
                      if (event.target.value == "three") {
                        setContentBlocks([{image: "", text: ""},{image: "", text: ""},{image: "", text: ""}])
                      } else if (event.target.value == "two") {
                        setContentBlocks([{image: "", text: ""},{image: "", text: ""}])
                      } else if (event.target.value == "one") {
                        setContentBlocks([{image: "", text: ""}])
                      } else if (event.target.value == "threemulti") {
                        setContentBlocks([{image: "", text: ""},{image: "", text: ""},{image: "", text: ""}])
                      }
                    }}>
                    <option value="text">Текстовый блок</option>
                    <option value="one">Одно изображение</option>
                    <option value="two">Два изображения</option>
                    <option value="three">Три изображения</option>
                    </select>
                  </span>
                </div>
              
              {contentType == "text" ? <>
                <div className={styles.xform_input}>
                  <label htmlFor="">Заголовок (необязательно)</label>
                  <input type="text" placeholder="Заголовок" value={contentTitle} onChange={(event) => setContentTitle(event.target.value)} />
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="">Заголовок (англ.)</label>
                  <input type="text" placeholder="Заголовок (англ.)" value={contentTitleEn} onChange={(event) => setContentTitleEn(event.target.value)} />
                </div> : <></>}
                
                <div className={styles.xform_input}>
                  <label htmlFor="">Текст</label>
                  <textarea rows={5} placeholder="Текст" value={contentText} onChange={(event) => setContentText(event.target.value)}></textarea>
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="">Текст (англ.)</label>
                  <textarea rows={5} placeholder="Текст (англ.)" value={contentTextEn} onChange={(event) => setContentTextEn(event.target.value)}></textarea>
                </div> : <></>}
              </> : <></>}

              <div className={styles.xform_input} style={{
                display: contentType == "three" || contentType == "threemulti" || (contentType == "text" && contentTitle.length != 0) ? "none" : "block"
              }}>
                <label htmlFor="bl_align">Расположение</label>
                <span className={styles.selc}>
                  <select name="bl_align" id="bl_align" value={contentAlign} onChange={(event) => {
                    setContentAlign(prev => prev = event.target.value)
                  }}>
                    <option value="left">Слева</option>
                    <option value="right">Справа</option>
                  </select>
                </span>
              </div>

              <div className={styles.xform_input} style={{display: contentType == "text" ? "none" : "block"}}>
                <label htmlFor="bl_align">Изображения</label>
                <div className={styles.images_list}>
                  {contentBlocks.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props34} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setContentBlocks(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push({...element, image: info.file.response.urls[0]})
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item.image})`}}><UploadOutlined rev={null} /></div></Upload>
                    </div>
                  </div>)}
                </div>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => addContentBlock()} style={{marginBottom: "0px"}}>Добавить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsContentAdd(false)} />

      <Modal
        visible={isContentEdit}
        title="Изменить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
            
                <div className={styles.xform_input}>
                  <label htmlFor="bl_type">Тип блока</label>
                  <span className={styles.selc}>
                    <select disabled={true} name="bl_type" id="bl_type" value={contentType} onChange={(event) => {
                      setContentType(prev => prev = event.target.value)
                    }}>
                      <option value="text">Текстовый блок</option>
                      <option value="one">Одно изображение</option>
                      <option value="two">Два изображения</option>
                      <option value="three">Три изображения</option>
                      <option value="threemulti">Три изображения, разная высота</option>
                    </select>
                  </span>
                </div>
              
              
              {contentType == "text" ? <>
                <div className={styles.xform_input}>
                  <label htmlFor="">Заголовок (необязательно)</label>
                  <input type="text" placeholder="Заголовок" value={contentTitle} onChange={(event) => setContentTitle(event.target.value)} />
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="">Заголовок (англ.)</label>
                  <input type="text" placeholder="Заголовок (англ.)" value={contentTitleEn} onChange={(event) => setContentTitleEn(event.target.value)} />
                </div> : <></>}
                
                <div className={styles.xform_input}>
                  <label htmlFor="">Текст</label>
                  <textarea rows={5} placeholder="Текст" value={contentText} onChange={(event) => setContentText(event.target.value)}></textarea>
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="">Текст (англ.)</label>
                  <textarea rows={5} placeholder="Текст (англ.)" value={contentTextEn} onChange={(event) => setContentTextEn(event.target.value)}></textarea>
                </div> : <></>}
              </> : <></>}

              <div className={styles.xform_input} style={{
                display: contentType == "three" || contentType == "threemulti" || (contentType == "text" && contentTitle.length != 0) ? "none" : "block"
              }}>
                <label htmlFor="bl_align">Расположение</label>
                <span className={styles.selc}>
                  <select name="bl_align" id="bl_align" value={contentAlign} onChange={(event) => {
                    setContentAlign(prev => prev = event.target.value)
                  }}>
                    <option value="left">Слева</option>
                    <option value="right">Справа</option>
                  </select>
                </span>
              </div>

              <div className={styles.xform_input} style={{display: contentType == "text" ? "none" : "block"}}>
                <label htmlFor="bl_align">Изображения</label>
                <div className={styles.images_list}>
                  {contentBlocks.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props34} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setContentBlocks(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push({...element, image: info.file.response.urls[0]})
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item.image})`}}><UploadOutlined rev={null} /></div></Upload>
                    </div>
                  </div>)}
                </div>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => saveContentBlock()} style={{marginBottom: "0px"}}>Сохранить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsContentEdit(false)} />

      <Modal
        visible={isContentDelete}
        title="Удалить блок"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить этот блок?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteContentBlock()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsContentDelete(false)} />

      <Modal
        visible={isDeleteVisible}
        title="Удалить проект"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить проект?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteSaleAction()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsDeleteVisible(false)} />

      <Modal
        visible={isDeleteImgVisible}
        onClose={() => setIsDeleteImgVisible(false)}
        title="Удалить изображение"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить изображение?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteImgAction()}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDataLoading}
        title="Загрузка..."
        content={<>
          <div className="modal_text">
            <p style={{textAlign: "center", padding: "32px 0"}}><LoadingOutlined rev={null} style={{color: "#000", fontSize: "42px"}} /></p>
          </div>
        </>}
        onClose={() => setIsDataLoading(false)} />
    </>
  )
}

export default observer(ProjectsManager)