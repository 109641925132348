import {DeleteOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons"
import { message, Switch, UploadProps } from "antd"
import Dragger from "antd/es/upload/Dragger"
import imageCompression from "browser-image-compression"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {ReactSortable} from "react-sortablejs"
import { Context } from "../../.."
import Modal from "../../../components/Modal"
import {ApiService} from "../../../core/api/api"
import { IXTeamItem, IXPartner, IXAboutSettings } from "../../../core/models/XAbout"
import {API_URL, StorageKeys} from "../../../core/utils/defaults"

import styles from "../../../styles/AdminManager.module.scss"

const AboutManager: FC = () => {

  const { adminStore, store } = useContext(Context)

  const location = useLocation()
  const navigate = useNavigate()

  const draggerProps: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif")) {
        return file
      } else {
        const options = { maxSizeMB: 3.0, maxWidthOrHeight: 3720, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  }

  const [activeTab, setActiveTab] = useState(1)

  const [pageTitle, setPageTitle] = useState("")
  const [pageTitleEn, setPageTitleEn] = useState("")

  const [aboutActive, setAboutActive] = useState(false)

  const [aboutTitle, setAboutTitle] = useState("")
  const [aboutTitleEn, setAboutTitleEn] = useState("")
  const [aboutText, setAboutText] = useState("")
  const [aboutTextEn, setAboutTextEn] = useState("")
  const [aboutImage, setAboutImage] = useState("")
  const [aboutImageMob, setAboutImageMob] = useState("")

  const [teamTitle, setTeamTitle] = useState("")
  const [teamTitleEn, setTeamTitleEn] = useState("")

  const [teamItemType, setTeamItemType] = useState("card" as "card" | "text")
  const [teamItemImage, setTeamItemImage] = useState("")
  const [teamItemText, setTeamItemText] = useState("")
  const [teamItemTextEn, setTeamItemTextEn] = useState("")
  const [teamItemName, setTeamItemName] = useState("")
  const [teamItemNameEn, setTeamItemNameEn] = useState("")
  const [selectedTeamItem, setSelectedTeamItem] = useState(null as null | IXTeamItem)
  const [isAddTeamItem, setIsAddTeamItem] = useState(false)
  const [isEditTeamItem, setIsEditTeamItem] = useState(false)
  const [isDeleteTeamItem, setIsDeleteTeamItem] = useState(false)
  
  const [teamList, setTeamList] = useState([] as IXTeamItem[])

  const [partnersTitle, setPartnersTitle] = useState("")
  const [partnersTitleEn, setPartnersTitleEn] = useState("")
  
  const [partnerImage, setPartnerImage] = useState("")
  const [partnerImageActive, setPartnerImageActive] = useState("")
  const [partnerUrl, setPartnerUrl] = useState("")
  const [selectedPartner, setSelectedPartner] = useState(null as IXPartner | null)
  const [isAddPartner, setIsAddPartner] = useState(false)
  const [isEditPartner, setIsEditPartner] = useState(false)
  const [isDeletePartner, setIsDeletePartner] = useState(false)
  
  const [isDataLoading, setIsDataLoading] = useState(false)
  
  const [partnersList, setPartnersList] = useState([] as IXPartner[])
  const [partnersListed, setPartnersListed] = useState([] as IXPartner[])

  const fetchAboutData = async () => {
    if (adminStore.aboutSettings != null) {
      setAboutActive(adminStore.aboutSettings.status)

      setPageTitle(adminStore.aboutSettings.title.ru)
      setPageTitleEn(adminStore.aboutSettings.title.en)

      setAboutTitle(adminStore.aboutSettings.about.title.ru)
      setAboutTitleEn(adminStore.aboutSettings.about.title.en)
      setAboutText(adminStore.aboutSettings.about.text.ru)
      setAboutTextEn(adminStore.aboutSettings.about.text.en)
      setAboutImage(adminStore.aboutSettings.about.image)
      setAboutImageMob(adminStore.aboutSettings.about.image_mob)

      setTeamTitle(adminStore.aboutSettings.team.title.ru)
      setTeamTitleEn(adminStore.aboutSettings.team.title.en)
      setTeamList(adminStore.aboutSettings.team.items)

      setPartnersTitle(adminStore.aboutSettings.partners.title.ru)
      setPartnersTitleEn(adminStore.aboutSettings.partners.title.en)
      setPartnersList(adminStore.aboutSettings.partners.items)
    } else {
      if (adminStore.aboutLoad < 3) {
        adminStore.setAboutLoad(adminStore.aboutLoad + 1)
        setTimeout(() => {
          fetchAboutData()
        }, 2000)
      } else {
        navigate("/")
      }
    }
  }

  const toggleState = async () => {

  }

  const buildFinalData = (): IXAboutSettings => {
    let newData: IXAboutSettings = {
      status: aboutActive,
      title: {
        ru: pageTitle,
        en: pageTitleEn
      },
      about: {
        title: {ru: aboutTitle, en: aboutTitleEn},
        text: {ru: aboutText, en: aboutTextEn},
        image: aboutImage,
        image_mob: aboutImageMob,
      },
      team: {
        title: {ru: teamTitle, en: teamTitleEn},
        items: teamList
      },
      partners: {
        title: {ru: partnersTitle, en: partnersTitleEn},
        items: partnersList
      }
    }

    return newData
  }

  const saveAboutData = async () => {
    setIsAddPartner(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()
    console.log(props)

    const res = await ApiService.core.updateSetting(9, {
      data: props
    })

    adminStore.setAboutSettings(props)
    adminStore.setIsAboutActive(props.status)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    message.success("Данные успешно обновлены!")

    setIsDataLoading(prev => prev = false)
  }

  const addPartner = async () => {
    setIsAddPartner(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()

    props.partners.items = [
      ...partnersList,
      {
        id: partnersList[partnersList.length - 1].id + 1,
        image: partnerImage,
        image_active: partnerImageActive,
        url: partnerUrl
      }
    ]

    console.log(props)

    const res = await ApiService.core.updateSetting(9, {
      data: props
    })

    adminStore.setAboutSettings(props)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    message.success("Данные успешно обновлены!")
    fetchAboutData()

    setIsDataLoading(prev => prev = false)
  }

  const savePartner = async () => {
    setIsEditPartner(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()

    let newPartners: IXPartner[] = []
    
    partnersList.forEach(el => {
      let nel = {
        id: el.id,
        image: el.image,
        image_active: el.image_active,
        url: el.url
      }
      console.log(nel, selectedPartner)
      if (el.id == selectedPartner!.id) {
        nel.image = partnerImage
        nel.image_active = partnerImageActive
        nel.url = partnerUrl
      }
      newPartners.push(nel)
    })
    props.partners.items = newPartners

    console.log(props)

    const res = await ApiService.core.updateSetting(9, {
      data: props
    })

    adminStore.setAboutSettings(props)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    message.success("Данные успешно обновлены!")
    fetchAboutData()

    setIsDataLoading(prev => prev = false)
  }

  const deletePartner = async () => {
    setIsDeletePartner(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()

    props.partners.items = partnersList.filter(v => v.id != selectedPartner!.id)

    console.log(props)

    const res = await ApiService.core.updateSetting(9, {
      data: props
    })

    adminStore.setAboutSettings(props)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    message.success("Данные успешно обновлены!")
    fetchAboutData()

    setIsDataLoading(prev => prev = false)
  }

  const addTeamItem = async () => {
    setIsAddTeamItem(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()

    props.team.items = [
      ...teamList,
      {
        id: teamList[teamList.length - 1].id + 1,
        image: teamItemImage,
        name: teamItemName,
        name_en: teamItemNameEn,
        text: teamItemText,
        text_en: teamItemTextEn,
        type: teamItemType
      }
    ]

    console.log(props)

    const res = await ApiService.core.updateSetting(9, {
      data: props
    })

    adminStore.setAboutSettings(props)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    message.success("Данные успешно обновлены!")
    fetchAboutData()

    setIsDataLoading(prev => prev = false)
  }

  const saveTeamItem = async () => {
    setIsEditTeamItem(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()

    let newTeam: IXTeamItem[] = []
    
    teamList.forEach(el => {
      let nel = {
        id: el.id,
        image: el.image,
        name: el.name,
        name_en: el.name_en,
        text: el.text,
        text_en: el.text_en,
        type: el.type
      }
      console.log(nel, selectedTeamItem)
      if (el.id == selectedTeamItem!.id) {
        nel.image = teamItemImage
        nel.name = teamItemName
        nel.name_en = teamItemNameEn
        nel.text = teamItemText
        nel.text_en = teamItemTextEn
        nel.type = teamItemType
      }
      newTeam.push(nel)
    })
    props.team.items = newTeam

    console.log(props)

    const res = await ApiService.core.updateSetting(9, {
      data: props
    })

    adminStore.setAboutSettings(props)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    message.success("Данные успешно обновлены!")
    fetchAboutData()

    setIsDataLoading(prev => prev = false)
  }

  const deleteTeamItem = async () => {
    setIsDeleteTeamItem(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()

    props.team.items = teamList.filter(v => v.id != selectedTeamItem!.id)

    console.log(props)

    const res = await ApiService.core.updateSetting(9, {
      data: props
    })

    adminStore.setAboutSettings(props)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    message.success("Данные успешно обновлены!")
    fetchAboutData()

    setIsDataLoading(prev => prev = false)
  }

  const isAboutDataChanged = () => {
    if (
      adminStore.aboutSettings != null &&
      (
        aboutActive != adminStore.aboutSettings.status ||
        pageTitle != adminStore.aboutSettings.title.ru ||
        pageTitleEn != adminStore.aboutSettings.title.en ||
        aboutTitle != adminStore.aboutSettings.about.title.ru ||
        aboutTitleEn != adminStore.aboutSettings.about.title.en ||
        aboutText != adminStore.aboutSettings.about.text.ru ||
        aboutTextEn != adminStore.aboutSettings.about.text.en ||
        aboutImage != adminStore.aboutSettings.about.image ||
        aboutImageMob != adminStore.aboutSettings.about.image_mob
      )
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    adminStore.setAboutLoad(0)
    fetchAboutData()
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div style={{marginTop: "-4px", marginBottom: "20px"}} className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
              <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>Основное</div>
              <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>Наша команда</div>
              <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 3)}>Наши партнеры</div>
            </div>
          </div>

          {activeTab == 1 ? <>
            <div className={"col-12 col-md-8 " + styles.mobbed} style={{marginBottom: "30px"}}>
              <div className={styles.grid_block}>
                <h3>Страница "О нас"</h3>
                
                <div className={styles.xforms}>
                  <div className={styles.xform_input__sw}>
                    <Switch defaultChecked={aboutActive} checked={aboutActive} id="trans_st" onChange={(event) => setAboutActive(prev => prev = event)} /> <span style={{marginLeft: "4px"}}>{adminStore.isAboutActive ? "Включена" : "Выключена"}</span>
                  </div>
                </div>

                <div className={styles.xform} style={{marginTop: "20px"}}>
                  <div className={styles.flexed}>
                    <div className={styles.xform_input}>
                      <label htmlFor="page_title">Заголовок страницы</label>
                      <input type="text" id="page_title" placeholder="Заголовок страницы" value={pageTitle} onChange={(event) => setPageTitle(event.target.value)} />
                    </div>
                    {adminStore.transActive ? <div className={styles.xform_input}>
                      <label htmlFor="page_title_en">Заголовок страницы (англ.)</label>
                      <input type="text" id="page_title_en" placeholder="Заголовок страницы (англ.)" value={pageTitle} onChange={(event) => setPageTitle(event.target.value)} />
                    </div> : <></>}
                  </div>
                  <div className={styles.flexed}>
                    <div className={styles.xform_input}>
                      <label htmlFor="page_title">Подзаголовок</label>
                      <input type="text" id="page_title" placeholder="Подзаголовок" value={aboutTitle} onChange={(event) => setAboutTitle(event.target.value)} />
                    </div>
                    {adminStore.transActive ? <div className={styles.xform_input}>
                      <label htmlFor="page_subtitle_en">Подзаголовок (англ.)</label>
                      <input type="text" id="page_subtitle_en" placeholder="Подзаголовок (англ.)" value={aboutTitleEn} onChange={(event) => setAboutTitleEn(event.target.value)} />
                    </div> : <></>}
                  </div>
                  <div className={styles.flexed}>
                    <div className={styles.xform_input}>
                      <label htmlFor="about_text">Описание</label>
                      <textarea rows={4} id="about_text" placeholder="Описание" value={aboutText} onChange={(event) => setAboutText(event.target.value)}></textarea>
                    </div>
                    {adminStore.transActive ? <div className={styles.xform_input}>
                      <label htmlFor="about_text_en">Описание (англ.)</label>
                      <textarea rows={4} id="about_text_en" placeholder="Описание (англ.)" value={aboutTextEn} onChange={(event) => setAboutTextEn(event.target.value)}></textarea>
                    </div> : <></>}
                  </div>

                  <Dragger {...draggerProps} className={styles.xuploaddd} style={{marginBottom: "32px"}} onChange={(info) => {
                    setIsDataLoading(prev => prev = false)
                    const { status } = info.file
                    if (status === 'done') {
                      message.success(`Файл успешно загружен!`)
                      setAboutImage(prev => prev = info.file.response.urls[0])
                    }
                  }}>
                    <div className={styles.xupload}>
                      <div className={styles.xupload_image} style={{backgroundImage: `url(${aboutImage})`}} onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()

                        store.setSelectedImages([{url: aboutImage, title: ""}])
                        store.setSelectedImageIndex(0)
                        store.setIsImageViewVisible(true)
                      }}></div>
                      <div className={styles.xupload_data}>
                        <h4>Главное изображение</h4>
                        <p>Нажмите или перенесите картинку в эту область для загрузки</p>
                      </div>
                    </div>
                  </Dragger>

                  <Dragger {...draggerProps} className={styles.xuploaddd} style={{marginBottom: "32px"}} onChange={(info) => {
                    setIsDataLoading(prev => prev = false)
                    const { status } = info.file
                    if (status === 'done') {
                      message.success(`Файл успешно загружен!`)
                      setAboutImageMob(prev => prev = info.file.response.urls[0])
                    }
                  }}>
                    <div className={styles.xupload}>
                      <div className={styles.xupload_image} style={{backgroundImage: `url(${aboutImageMob})`}} onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()

                        store.setSelectedImages([{url: aboutImageMob, title: ""}])
                        store.setSelectedImageIndex(0)
                        store.setIsImageViewVisible(true)
                      }}></div>
                      <div className={styles.xupload_data}>
                        <h4>Главное изображение (моб.)</h4>
                        <p>Нажмите или перенесите картинку в эту область для загрузки</p>
                      </div>
                    </div>
                  </Dragger>

                </div>

                <button className={styles.xbtn} disabled={!isAboutDataChanged()} onClick={() => saveAboutData()}>Сохранить</button>
              </div>
            </div>
          </> : <></>}

          {activeTab == 2 ? <>
            <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px"}}>
              <div className={styles.grid_block}>
                <h3>Наша команда</h3>

                <div className={styles.xform} style={{marginTop: "20px", paddingBottom: "20px", borderBottom: "1px solid #ededed"}}>
                  <div className={styles.flexed}>
                    <div className={styles.xform_input}>
                      <label htmlFor="prtnr_title">Заголовок</label>
                      <input type="text" id="prtnr_title" placeholder="Заголовок" value={teamTitle} onChange={(event) => setTeamTitle(event.target.value)} />
                    </div>
                    {adminStore.transActive ? <div className={styles.xform_input}>
                      <label htmlFor="prtnr_title_en">Заголовок (англ.)</label>
                      <input type="text" id="prtnr_title_en" placeholder="Заголовок (англ.)" value={teamTitleEn} onChange={(event) => setTeamTitleEn(event.target.value)} />
                    </div> : <></>}
                  </div>

                  <button className={styles.xbtn} onClick={() => saveAboutData()}>Сохранить</button>
              
                </div>

                <div className={styles.setting_block} style={{marginTop: "20px"}}>
                  <h3>Команда</h3>

                  <div className={styles.xactions} style={{top: "-3px"}}>
                    <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                      setTeamItemType("card")
                      setTeamItemImage("")
                      setTeamItemName("")
                      setTeamItemNameEn("")
                      setTeamItemText("")
                      setTeamItemTextEn("")
                      setSelectedTeamItem(null)
                      setIsAddTeamItem(true)
                    }}>Добавить блок</div>
                  </div>

                  <p className={styles.xdesc}>Вы можете менять расположение блоков.<br/>Для этого зажмите блок и перенесите на нужное место, взамен другого.</p>

                  <div className={styles.xgrid_logos}>
                    <ReactSortable className={styles.xgrid} list={teamList} setList={setTeamList}>
                      {teamList.map((item: IXTeamItem, ix) => <div className={styles.xgrid_logos__item}>
                        {item.type != "text" ? <div className={styles.xgrid_logos__item__img} style={{padding: "10px 10px 0px 10px", height: "200px"}}>
                          <div style={{backgroundImage: `url(${item.image})`, backgroundSize: "cover"}}></div>
                        </div> : <></>}
                        <p>{item.name}<br/><small>{item.text}</small></p>
                        <div className={styles.xgrid_logos__item__actions}>
                          <div>
                            <button onClick={() => {
                              setTeamItemImage(item.image!)
                              setTeamItemName(item.name!)
                              setTeamItemNameEn(item.name_en!)
                              setTeamItemText(item.text!)
                              setTeamItemType(item.type!)
                              setTeamItemTextEn(item.text_en!)
                              setSelectedTeamItem(item)
                              setIsEditTeamItem(true)
                            }}><EditOutlined rev={null} /></button>
                            <button onClick={() => {
                              setSelectedTeamItem(item)
                              setIsDeleteTeamItem(true)
                            }}><DeleteOutlined rev={null} /></button>
                          </div>
                        </div>
                      </div>)}
                    </ReactSortable>
                  </div>
                  
                  <button className={styles.xbtn} onClick={() => saveAboutData()}>Сохранить</button>
              
                </div>
              </div>
            </div>
          </> : <></>}

          {activeTab == 3 ? <>
            <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px"}}>
              <div className={styles.grid_block}>
                <h3>Наши партнеры</h3>

                <div className={styles.xform} style={{marginTop: "20px", paddingBottom: "20px", borderBottom: "1px solid #ededed"}}>
                  <div className={styles.flexed}>
                    <div className={styles.xform_input}>
                      <label htmlFor="prtnr_title">Заголовок</label>
                      <input type="text" id="prtnr_title" placeholder="Заголовок" value={partnersTitle} onChange={(event) => setPartnersTitle(event.target.value)} />
                    </div>
                    {adminStore.transActive ? <div className={styles.xform_input}>
                      <label htmlFor="prtnr_title_en">Заголовок (англ.)</label>
                      <input type="text" id="prtnr_title_en" placeholder="Заголовок (англ.)" value={partnersTitleEn} onChange={(event) => setPartnersTitleEn(event.target.value)} />
                    </div> : <></>}
                  </div>

                  <button className={styles.xbtn} onClick={() => saveAboutData()}>Сохранить</button>
              
                </div>

                <div className={styles.setting_block} style={{marginTop: "20px"}}>
                  <h3>Список партнеров</h3>

                  <div className={styles.xactions} style={{top: "-3px"}}>
                    <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                      setPartnerImage("")
                      setPartnerImageActive("")
                      setPartnerUrl("")
                      setSelectedPartner(null)
                      setIsAddPartner(true)
                    }}>Добавить партнера</div>
                  </div>

                  <p className={styles.xdesc}>Вы можете менять расположение партнеров.<br/>Для этого зажмите партнера и перенесите на нужное место, взамен другого.</p>

                  <div className={styles.xgrid_logos}>
                    <ReactSortable className={styles.xgrid} list={partnersList} setList={setPartnersList}>
                      {partnersList.map((item: IXPartner, ix) => <div className={styles.xgrid_logos__item}>
                        <div className={styles.xgrid_logos__item__img}>
                          <div style={{backgroundImage: `url(${item.image})`}}></div>
                          <div style={{backgroundImage: `url(${item.image_active})`}}></div>
                        </div>
                        <div className={styles.xgrid_logos__item__actions}>
                          <div>
                            <button onClick={() => {
                              setPartnerImage(item.image)
                              setPartnerImageActive(item.image_active)
                              setPartnerUrl(item.url)
                              setSelectedPartner(item)
                              setIsEditPartner(true)
                            }}><EditOutlined rev={null} /></button>
                            <button onClick={() => {
                              setSelectedPartner(item)
                              setIsDeletePartner(true)
                            }}><DeleteOutlined rev={null} /></button>
                          </div>
                        </div>
                      </div>)}
                    </ReactSortable>
                  </div>
                  
                  <button className={styles.xbtn} onClick={() => saveAboutData()}>Сохранить</button>
              
                  {/*<div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                    <div className={styles.xtable}>
                      <div className={styles.xtable_head}>
                        <div>#</div>
                        <div>Изображение</div>
                        <div>URL</div>
                        <div>Действия</div>
                      </div>
                      <div className={styles.xtable_body}>
                        {partnersList.map((item: IXPartner, ix) => <div className={styles.xlist_item}>
                          <div className={styles.xlist_item__id}>{ix + 1}</div>
                          <div className={styles.xlist_item__name} onClick={() => {
                            setPartnerImage(item.image)
                            setPartnerImageActive(item.image_active)
                            setPartnerUrl(item.url)
                            setSelectedPartner(item)
                            setIsEditPartner(true)
                          }}><img src={item.image} style={{height: "18px"}} /></div>
                          <div className={styles.xlist_item__pagename}>{item.url}</div>
                          <div className={styles.xlist_item__actions}>
                            <button onClick={() => {
                              setPartnerImage(item.image)
                              setPartnerImageActive(item.image_active)
                              setPartnerUrl(item.url)
                              setSelectedPartner(item)
                              setIsEditPartner(true)
                            }}><i className="far fa-pen-to-square"></i></button>
                            <button onClick={() => {
                              setSelectedPartner(item)
                              setIsDeletePartner(true)
                            }}><i className="far fa-trash"></i></button>
                          </div>
                        </div>)}
                      </div>
                    </div>
                  </div>*/} 

                </div>

              </div>
            </div>
          </> : <></>}

        </div>
      </div>

      <Modal
        visible={isAddTeamItem}
        title="Добавить блок"
        onClose={() => setIsAddTeamItem(prev => prev = false)}
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="bl_type">Тип блока</label>
                <span className={styles.selc}>
                  <select name="bl_type" id="bl_type" value={teamItemType} onChange={(event) => {
                    setTeamItemType(prev => prev = event.target.value as "card" | "text")
                  
                    if (event.target.value == "text") {
                      setTeamItemImage("")
                    } else if (event.target.value == "card") {
                      
                    }
                  }}>
                    <option value="card">Карточка сотрудника</option>
                    <option value="text">Текстовый блок</option>
                  </select>
                </span>
              </div>

              <div className={styles.flexed}>
                <div className={styles.xform_input}>
                  <label htmlFor="edit_ti_name">Имя</label>
                  <input type="text" id="edit_ti_name" placeholder="Имя" value={teamItemName} onChange={(event) => setTeamItemName(event.target.value)} />
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="edit_ti_name_en">Имя (англ.)</label>
                  <input type="text" id="edit_ti_name_en" placeholder="Имя (англ.)" value={teamItemNameEn} onChange={(event) => setTeamItemNameEn(event.target.value)} />
                </div> : <></>}
              </div>

              <div className={styles.flexed}>
                <div className={styles.xform_input}>
                  <label htmlFor="edit_ti_text">Текст</label>
                  <textarea rows={4} id="edit_ti_text" placeholder="Текст" value={teamItemText} onChange={(event) => setTeamItemText(event.target.value)}></textarea>
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="edit_ti_text_en">Текст (англ.)</label>
                  <textarea rows={4} id="edit_ti_text_en" placeholder="Текст (англ.)" value={teamItemTextEn} onChange={(event) => setTeamItemTextEn(event.target.value)}></textarea>
                </div> : <></>}
              </div>

              {teamItemType == "card" ? <Dragger {...draggerProps} className={styles.xuploaddd} style={{marginBottom: "32px"}} onChange={(info) => {
                setIsDataLoading(prev => prev = false)
                const { status } = info.file
                if (status === 'done') {
                  message.success(`Файл успешно загружен!`)
                  setTeamItemImage(prev => prev = info.file.response.urls[0])
                }
              }}>
                <div className={styles.xupload}>
                  <div className={styles.xupload_image} style={{backgroundImage: `url(${teamItemImage})`}} onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    store.setSelectedImages([{url: teamItemImage, title: ""}])
                    store.setSelectedImageIndex(0)
                    store.setIsImageViewVisible(true)
                  }}></div>
                  <div className={styles.xupload_data}>
                    <h4>Изображение</h4>
                    <p>Нажмите или перенесите картинку в эту область для загрузки</p>
                  </div>
                </div>
              </Dragger> : <></>}
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => addTeamItem()}>Добавить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isEditTeamItem}
        title="Изменить блок"
        onClose={() => setIsEditTeamItem(prev => prev = false)}
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="bl_type">Тип блока</label>
                <span className={styles.selc}>
                  <select name="bl_type" id="bl_type" value={teamItemType} onChange={(event) => {
                    setTeamItemType(prev => prev = event.target.value as "card" | "text")
                  
                    if (event.target.value == "text") {
                      setTeamItemImage("")
                    } else if (event.target.value == "card") {
                      
                    }
                  }}>
                  <option value="card">Карточка сотрудника</option>
                  <option value="text">Текстовый блок</option>
                  </select>
                </span>
              </div>

              <div className={styles.flexed}>
                <div className={styles.xform_input}>
                  <label htmlFor="edit_ti_name">Имя</label>
                  <input type="text" id="edit_ti_name" placeholder="Имя" value={teamItemName} onChange={(event) => setTeamItemName(event.target.value)} />
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="edit_ti_name_en">Имя (англ.)</label>
                  <input type="text" id="edit_ti_name_en" placeholder="Имя (англ.)" value={teamItemNameEn} onChange={(event) => setTeamItemNameEn(event.target.value)} />
                </div> : <></>}
              </div>

              <div className={styles.flexed}>
                <div className={styles.xform_input}>
                  <label htmlFor="edit_ti_text">Текст</label>
                  <textarea rows={4} id="edit_ti_text" placeholder="Текст" value={teamItemText} onChange={(event) => setTeamItemText(event.target.value)}></textarea>
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="edit_ti_text_en">Текст (англ.)</label>
                  <textarea rows={4} id="edit_ti_text_en" placeholder="Текст (англ.)" value={teamItemTextEn} onChange={(event) => setTeamItemTextEn(event.target.value)}></textarea>
                </div> : <></>}
              </div>

              {teamItemType == "card" ? <Dragger {...draggerProps} className={styles.xuploaddd} style={{marginBottom: "32px"}} onChange={(info) => {
                setIsDataLoading(prev => prev = false)
                const { status } = info.file
                if (status === 'done') {
                  message.success(`Файл успешно загружен!`)
                  setTeamItemImage(prev => prev = info.file.response.urls[0])
                }
              }}>
                <div className={styles.xupload}>
                  <div className={styles.xupload_image} style={{backgroundImage: `url(${teamItemImage})`}} onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    store.setSelectedImages([{url: teamItemImage, title: ""}])
                    store.setSelectedImageIndex(0)
                    store.setIsImageViewVisible(true)
                  }}></div>
                  <div className={styles.xupload_data}>
                    <h4>Изображение</h4>
                    <p>Нажмите или перенесите картинку в эту область для загрузки</p>
                  </div>
                </div>
              </Dragger> : <></>}
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => saveTeamItem()}>Сохранить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeleteTeamItem}
        title="Удалить блок"
        onClose={() => setIsDeleteTeamItem(prev => prev = false)}
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить блок из команды?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteTeamItem()}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isAddPartner}
        title="Добавить партнера"
        onClose={() => setIsAddPartner(prev => prev = false)}
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="">Ссылка</label>
                <input type="text" placeholder="Ссылка" value={partnerUrl} onChange={(event) => setPartnerUrl(event.target.value)} />
              </div>

              <Dragger {...draggerProps} className={styles.xuploaddd} style={{marginBottom: "32px"}} onChange={(info) => {
                setIsDataLoading(prev => prev = false)
                const { status } = info.file
                if (status === 'done') {
                  message.success(`Файл успешно загружен!`)
                  setPartnerImage(prev => prev = info.file.response.urls[0])
                }
              }}>
                <div className={styles.xupload}>
                  <div className={styles.xupload_image} style={{backgroundImage: `url(${partnerImage})`}} onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    store.setSelectedImages([{url: partnerImage, title: ""}])
                    store.setSelectedImageIndex(0)
                    store.setIsImageViewVisible(true)
                  }}></div>
                  <div className={styles.xupload_data}>
                    <h4>Логотип</h4>
                    <p>Нажмите или перенесите картинку в эту область для загрузки.<br/><small><b>Изображение должно быть 104 пикселя по высоте!</b></small></p>
                  </div>
                </div>
              </Dragger>

              <Dragger {...draggerProps} className={styles.xuploaddd} style={{marginBottom: "32px"}} onChange={(info) => {
                setIsDataLoading(prev => prev = false)
                const { status } = info.file
                if (status === 'done') {
                  message.success(`Файл успешно загружен!`)
                  setPartnerImageActive(prev => prev = info.file.response.urls[0])
                }
              }}>
                <div className={styles.xupload}>
                  <div className={styles.xupload_image} style={{backgroundImage: `url(${partnerImageActive})`}} onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    store.setSelectedImages([{url: partnerImageActive, title: ""}])
                    store.setSelectedImageIndex(0)
                    store.setIsImageViewVisible(true)
                  }}></div>
                  <div className={styles.xupload_data}>
                    <h4>Активный логотип</h4>
                    <p>Нажмите или перенесите картинку в эту область для загрузки.<br/><small><b>Изображение должно быть 104 пикселя по высоте!</b></small></p>
                  </div>
                </div>
              </Dragger>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => addPartner()}>Добавить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isEditPartner}
        title="Изменить партнера"
        onClose={() => setIsEditPartner(prev => prev = false)}
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="">Ссылка</label>
                <input type="text" placeholder="Ссылка" value={partnerUrl} onChange={(event) => setPartnerUrl(event.target.value)} />
              </div>

              <Dragger {...draggerProps} className={styles.xuploaddd} style={{marginBottom: "32px"}} onChange={(info) => {
                setIsDataLoading(prev => prev = false)
                const { status } = info.file
                if (status === 'done') {
                  message.success(`Файл успешно загружен!`)
                  setPartnerImage(prev => prev = info.file.response.urls[0])
                }
              }}>
                <div className={styles.xupload}>
                  <div className={styles.xupload_image} style={{backgroundImage: `url(${partnerImage})`}} onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    store.setSelectedImages([{url: partnerImage, title: ""}])
                    store.setSelectedImageIndex(0)
                    store.setIsImageViewVisible(true)
                  }}></div>
                  <div className={styles.xupload_data}>
                    <h4>Логотип</h4>
                    <p>Нажмите или перенесите картинку в эту область для загрузки.<br/><small><b>Изображение должно быть 104 пикселя по высоте!</b></small></p>
                  </div>
                </div>
              </Dragger>

              <Dragger {...draggerProps} className={styles.xuploaddd} style={{marginBottom: "32px"}} onChange={(info) => {
                setIsDataLoading(prev => prev = false)
                const { status } = info.file
                if (status === 'done') {
                  message.success(`Файл успешно загружен!`)
                  setPartnerImageActive(prev => prev = info.file.response.urls[0])
                }
              }}>
                <div className={styles.xupload}>
                  <div className={styles.xupload_image} style={{backgroundImage: `url(${partnerImageActive})`}} onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    store.setSelectedImages([{url: partnerImageActive, title: ""}])
                    store.setSelectedImageIndex(0)
                    store.setIsImageViewVisible(true)
                  }}></div>
                  <div className={styles.xupload_data}>
                    <h4>Активный логотип</h4>
                    <p>Нажмите или перенесите картинку в эту область для загрузки.<br/><small><b>Изображение должно быть 104 пикселя по высоте!</b></small></p>
                  </div>
                </div>
              </Dragger>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => savePartner()}>Сохранить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeletePartner}
        title="Удалить партнера"
        onClose={() => setIsDeletePartner(prev => prev = false)}
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить партнера?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deletePartner()}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDataLoading}
        title="Загрузка..."
        content={<>
          <div className="modal_text">
            <p style={{textAlign: "center", padding: "32px 0"}}><LoadingOutlined rev={null} style={{color: "#000", fontSize: "42px"}} /></p>
          </div>
        </>}
        onClose={() => setIsDataLoading(false)} />
    </>
  )
}

export default observer(AboutManager)