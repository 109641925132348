import { observer } from "mobx-react-lite";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { XImage } from "../core/models/XImage";
import { SliderToggleDirection } from "../core/utils/defaults";

import styles from "../styles/ModalImageView.module.scss";

interface ModalProps {
  visible: boolean
  images: XImage[]
  selectedIndex: number
  onSelectImage: (index: number) => void
  onToggleImage: (dir: SliderToggleDirection) => void
  onClose: () => void
}

const ImageViewModal: FC<ModalProps> = ({visible, images, selectedIndex, onSelectImage, onToggleImage, onClose}) => {

  const [ hidingProceed, setHidingProceed ] = useState(false);

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case 'Escape':
        setHidingProceed(true);
        document.getElementsByTagName("html")[0].style.overflowY = "unset"
        setTimeout(() => {
          onClose();
          setHidingProceed(false);
        }, 300);
        break;
    }
  }

  const onCloseRef = () => {
    setHidingProceed(true);
    document.getElementsByTagName("html")[0].style.overflowY = "unset"
    setTimeout(() => {
      onClose();
      setHidingProceed(false);
    }, 300);
  }

  React.useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => document.removeEventListener('keydown', onKeydown)
  })

  useEffect(() => {
    if (visible) {
      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
    } else {
      document.getElementsByTagName("html")[0].style.overflowY = "unset"
    }
  }, [visible])

  if (!visible) return null
  
  return (
    <div className={styles.mdl}>
      <div className={styles.modal} style={{opacity: hidingProceed ? "0" : "1"}}>
        <div className={styles.modal_close} onClick={onCloseRef}><i className="far fa-xmark"></i></div>
        
        {images.length > 1 ? <>
          <div className={styles.modal_prev} onClick={() => onToggleImage("prev")}><i className="far fa-chevron-left"></i></div>
          <div className={styles.modal_next} onClick={() => onToggleImage("next")}><i className="far fa-chevron-right"></i></div>
        </> : <></>}
      
        <div className={styles.modal_image}>
          <div className={styles.image}><img src={images[selectedIndex].url} /></div>
          {images.length > 1 ? <div className={styles.minis}>
            <div className={styles.minis_wrap}>
              {images.map((img, ix) => <div className={images[selectedIndex].url == img.url ? styles.active : ""} onClick={() => onSelectImage(ix)} style={{backgroundImage: `url(${img.url})`}}></div>)}
            </div>
          </div> : <></>}
          {images[selectedIndex].title != "" ? <div className={styles.title} dangerouslySetInnerHTML={{__html: images[selectedIndex].title}}></div> : <></>}
          {images[selectedIndex].title == "" ? <div className={styles.title}>{selectedIndex + 1} / {images.length}</div> : <></>}
        </div>
      </div>
    </div>
  )
}

export default observer(ImageViewModal)