import {DeleteOutlined, LoadingOutlined} from "@ant-design/icons"
import {message, Upload, UploadProps} from "antd"
import imageCompression from "browser-image-compression"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import Modal from "../../../components/Modal"
import {ApiService} from "../../../core/api/api"
import {IXSocial} from "../../../core/models/XContacts"
import {API_URL, StorageKeys} from "../../../core/utils/defaults"

import styles from "../../../styles/AdminManager.module.scss"

const ContactsManager: FC = () => {

  const { store, adminStore } = useContext(Context)
  const location = useLocation()
  
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isAddVisible, setIsAddVisible] = useState(false)
  const [isEditVisible, setIsEditVisible] = useState(false)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isDeleteImgVisible, setIsDeleteImgVisible] = useState(false)
  
  const [selectedImg, setSelectedImg] = useState(0)

  const [socialSel, setSocialSel] = useState(0)
  const [socialName, setSocialName] = useState("")
  const [socialUrl, setSocialUrl] = useState("")

  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [addressEn, setAddressEn] = useState("")
  const [addressUrl, setAddressUrl] = useState("")
  const [socials, setSocials] = useState([] as IXSocial[])
  const [images, setImages] = useState([] as string[])

  const [selectedSocial, setSelectedSocial] = useState(null as null | IXSocial)

  const props: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif")) {
        return file
      } else {
        const options = { maxSizeMB: 2.5, maxWidthOrHeight: 2720, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onChange: async (info) => {
      setIsDataLoading(prev => prev = false)

      const { status } = info.file;
      if (status === 'done') {
        message.success(`Файл успешно загружен!`);
        console.log(info.file.response)
        setImages(prev => prev = [...prev, info.file.response.urls[0]])
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  }

  const fetchData = () => {
    if (adminStore.contacts != null) {
      setPhone(adminStore.contacts.phone)
      setEmail(adminStore.contacts.email)
      setAddress(adminStore.contacts.address)
      setAddressEn(adminStore.contacts.address_en!)
      setAddressUrl(adminStore.contacts.address_url)
      setSocials(adminStore.contacts.socials)
      setImages(adminStore.contacts.images)
    }
  }

  const deleteImgAction = async () => {
    let newImgs: string[] = images.filter((item, ix) => ix != selectedImg)
    setIsDeleteImgVisible(prev => prev = false)
    saveAction(newImgs)
  }

  const saveAction = async (imgs: string[] | null = null) => {
    setIsDataLoading(prev => prev = true)

    let newData: any = {
      phone: phone,
      email: email,
      address: address,
      address_en: addressEn,
      address_url: addressUrl,
      socials: socials,
      images: imgs == null ? images : imgs
    }

    const res = await ApiService.core.updateSetting(1, {
      data: newData
    })

    adminStore.setContacts(newData)
    localStorage.setItem(StorageKeys.SETTINGS_CONTACTS, JSON.stringify(newData))
  
    message.success("Контактные данные успешно обновлены!")

    fetchData()

    setIsDataLoading(prev => prev = false)
  }

  const addSocialAction = async () => {
    setIsAddVisible(false)
    setIsDataLoading(true)

    let newSocials: IXSocial[] = [...socials, {
      name: socialName,
      url: socialUrl
    }]

    let newData: any = {
      phone: phone,
      email: email,
      address: address,
      address_en: addressEn,
      address_url: addressUrl,
      socials: newSocials,
      images: images
    }

    const res = await ApiService.core.updateSetting(1, {
      data: newData
    })

    adminStore.setContacts(newData)
    localStorage.setItem(StorageKeys.SETTINGS_CONTACTS, JSON.stringify(newData))

    fetchData()

    setIsDataLoading(false)
  }

  const editSocialAction = async () => {
    setIsEditVisible(false)
    setIsDataLoading(true)

    let newSocials: IXSocial[] = []
    socials.forEach((social, ix) => {
      if (ix == socialSel) {
        social.name = socialName
        social.url = socialUrl
      }
      newSocials.push(social)
    })

    let newData: any = {
      phone: phone,
      email: email,
      address: address,
      address_en: addressEn,
      address_url: addressUrl,
      socials: newSocials,
      images: images
    }

    const res = await ApiService.core.updateSetting(1, {
      data: newData
    })

    adminStore.setContacts(newData)
    localStorage.setItem(StorageKeys.SETTINGS_CONTACTS, JSON.stringify(newData))

    fetchData()

    setIsDataLoading(false)
  }

  const deleteSocialAction = async () => {
    setIsDeleteVisible(false)
    setIsDataLoading(true)

    let newSocials: IXSocial[] = []
    socials.forEach((social, ix) => {
      if (ix != socialSel) {
        newSocials.push(social)
      }
    })

    let newData: any = {
      phone: phone,
      email: email,
      address: address,
      address_en: addressEn,
      address_url: addressUrl,
      socials: newSocials,
      images: images
    }

    const res = await ApiService.core.updateSetting(1, {
      data: newData
    })

    adminStore.setContacts(newData)
    localStorage.setItem(StorageKeys.SETTINGS_CONTACTS, JSON.stringify(newData))

    fetchData()

    setIsDataLoading(false)
  }

  const handleOver = (ev: any) => {
    ev.preventDefault();
  }

  const handleDrag = (ev: any) => {
    if (ev.currentTarget != undefined) {
      adminStore.setDragId(parseInt(ev.currentTarget.id));
    }
  }

  const handleDrop = async (ev: any) => {
    ev.preventDefault();
    const dragItem = images.findIndex((item: any, ix) => ix == adminStore.dragId);
    const dropItem = images.findIndex(
      (item: any, ix) => ix == parseInt(ev.currentTarget.id)
    );
    
    const arr = moveItem(dragItem! - 1, dropItem! - 1)

    console.log(arr)

    setImages(prev => prev = arr)

    saveAction(arr)
  }

  const moveItem = (from: number, to: number) => {
    const f = images.findIndex((v: any, ix) => ix == (from + 1));
    const t = images.findIndex((v: any, ix) => ix == (to + 1));
    let slds: any[] = [];
    let sldsEx: any[] = [...images];

    //store.aSlides.splice(to, 0, f)
    images.forEach((itm: any, ix: number) => {
      if (ix == f) {
        slds.push(images[t])
      } else if (ix == t) {
        slds.push(images[f])
      } else {
        slds.push(itm)
      }
    })

    return slds
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className={"col-12 col-md-5 " + styles.mobbed}>
            <div className={styles.setting_block}>
              <h3>Контакты</h3>
              
              <div className={styles.xform}>
                <div className={styles.xform_input}>
                  <label htmlFor="c_phone">Телефон</label>
                  <input type="text" id="c_phone" placeholder="Телефон" value={phone} onChange={(event) => setPhone(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="c_email">Email</label>
                  <input type="text" id="c_email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                </div>

                <div className={styles.xform_input}>
                  <label htmlFor="c_addr">Адрес</label>
                  <input type="text" id="c_addr" placeholder="Адрес" value={address} onChange={(event) => setAddress(event.target.value)} />
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="c_addr_en">Адрес (англ.)</label>
                  <input type="text" id="c_addr_en" placeholder="Адрес (англ.)" value={addressEn} onChange={(event) => setAddressEn(event.target.value)} />
                </div> : <></>}
                
                <div className={styles.xform_input}>
                  <label htmlFor="c_addr_url">Ссылка на карты (Адрес)</label>
                  <input type="text" id="c_addr_url" placeholder="Ссылка на карты" value={addressUrl} onChange={(event) => setAddressUrl(event.target.value)} />
                </div>
              </div>

              <button className={styles.xbtn} onClick={() => saveAction()}>Сохранить</button>
            </div>
          </div>
          <div className={"col-12 col-md-7 " + styles.mobbed} style={{position: "relative"}}>
            <div className={styles.setting_block}>
              <h3>Соц. сети</h3>

              <div className={styles.xactions} style={{top: "-10px"}}>
                <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                  setSocialName("")
                  setSocialUrl("")
                  setIsAddVisible(true)
                }}>Добавить соц. сеть</div>
              </div>

              <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                <div className={styles.xtable}>
                  <div className={styles.xtable_head}>
                    <div>#</div>
                    <div>Наименование</div>
                    <div>URL</div>
                    <div>Действия</div>
                  </div>
                  <div className={styles.xtable_body}>
                    {socials.map((item: IXSocial, ix) => <div className={styles.xlist_item}>
                      <div className={styles.xlist_item__id}>{ix + 1}</div>
                      <div className={styles.xlist_item__name} onClick={() => {
                        setSocialName(item.name!)
                        setSocialUrl(item.url!)
                        setSocialSel(ix)
                        setSelectedSocial(item)
                        setIsEditVisible(true)
                      }}><span>{item.name}</span></div>
                      <div className={styles.xlist_item__pagename}>{item.url}</div>
                      <div className={styles.xlist_item__actions}>
                        <button onClick={() => {
                          setSocialName(item.name!)
                          setSocialUrl(item.url!)
                          setSocialSel(ix)
                          setSelectedSocial(item)
                          setIsEditVisible(true)
                        }}><i className="far fa-pen-to-square"></i></button>
                        <button onClick={() => {
                          setSocialSel(ix)
                          setSelectedSocial(item)
                          setIsDeleteVisible(true)
                        }}><i className="far fa-trash"></i></button>
                      </div>
                    </div>)}
                  </div>
                </div>
              </div> 

            </div>
          </div>

          <div className={"col-12 col-md-12 " + styles.mobbed} style={{position: "relative", marginTop: "32px", borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
            <div className={styles.setting_block} style={{position: "relative"}}>
              <h3>Изображения</h3>

              <div className={styles.xactions} style={{top: "-10px"}}>
                {images.length == 3 ? <div className={styles.xactions_item + " " + styles.successs + " " + styles.disabled}>Добавить изображение</div> : <Upload {...props}><div className={styles.xactions_item + " " + styles.successs}>Добавить изображение</div></Upload>}
              </div>

              <p className={styles.xdesc}>Вы можете менять расположение изображений.<br/>Для этого зажмите картинку и перенесите на нужное место, взамен другой.</p>
                  
              <div className={styles.flexed_imgs}>
                {images.map((image, ix) => <div 
                  draggable={true}
                  key={ix}
                  id={`${ix}`}
                  onDragOver={(e) => handleOver(e)}
                  onDragStart={(e) => handleDrag(e)}
                  onDrop={(e) => handleDrop(e)}
                  style={{backgroundImage: `url(${image})`}}>
                  <DeleteOutlined rev={null} onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()

                    setSelectedImg(ix)
                    setIsDeleteImgVisible(true)
                  }} />
                </div>)}
              </div>
            </div>
          </div>

        </div>
      </div>

      <Modal
        visible={isAddVisible}
        onClose={() => setIsAddVisible(false)}
        title="Добавить соц. сеть"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="add_name">Наименование</label>
                <input type="text" id="add_name" placeholder="Наименование соц. сети" value={socialName} onChange={(event) => setSocialName(prev => prev = event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="add_url">Ссылка</label>
                <input type="text" id="add_url" placeholder="Ссылка на соц. сеть" value={socialUrl} onChange={(event) => setSocialUrl(prev => prev = event.target.value)} />
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => addSocialAction()}>Добавить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isEditVisible}
        onClose={() => setIsEditVisible(false)}
        title="Изменить соц. сеть"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="add_name">Наименование</label>
                <input type="text" id="add_name" placeholder="Наименование соц. сети" value={socialName} onChange={(event) => setSocialName(prev => prev = event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="add_url">Ссылка</label>
                <input type="text" id="add_url" placeholder="Ссылка на соц. сеть" value={socialUrl} onChange={(event) => setSocialUrl(prev => prev = event.target.value)} />
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => editSocialAction()}>Сохранить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeleteVisible}
        onClose={() => setIsDeleteVisible(false)}
        title="Удалить соц. сеть"
        content={selectedSocial != null ? <>
          <div className="modal_text">
            <p>Вы действительно хотите удалить соц. сеть "{selectedSocial.name}"?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteSocialAction()}>Удалить</button>
            </div>
          </div>
        </> : <></>} />

      <Modal
        visible={isDeleteImgVisible}
        onClose={() => setIsDeleteImgVisible(false)}
        title="Удалить изображение"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить изображение?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteImgAction()}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDataLoading}
        title="Загрузка..."
        content={<>
          <div className="modal_text">
            <p style={{textAlign: "center", padding: "32px 0"}}><LoadingOutlined rev={null} style={{color: "#000", fontSize: "42px"}} /></p>
          </div>
        </>}
        onClose={() => setIsDataLoading(false)} />
    </>
  )
}

export default observer(ContactsManager)